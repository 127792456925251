// 埋点方法
// import { useRoute } from '@/modules/router'
import { useUserStore } from '@/store/user'
import { isZh } from '@/utils/const'
import { capitalize } from '@/utils'

export default function (id: string, option = {}) {
  const query = useRoute()?.query || {}
  if (import.meta.env.MODE === 'development') return
  _zaq.push(['_setAppId', 1])

  const lang = useAppStore().language

  const config: Record<string, unknown> = {
    region: isZh ? 'tzh' : `t${lang}`,
    uid: useUserStore()?.userInfo.id || 0,
    pkg: query?.pkg || '',
    platform: 'web',
    version: query?.version || '',
    system: 'PC',
    ...option,
  }
  if (isZh) config['lang-script'] = capitalize(lang)
  _zaq.push(['_trackEvent', id, JSON.stringify(config)])
}
