import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import { languageTransform } from './languageTranform'
import { useUserStore } from '@/store/user'
import { useAppStore } from '@/store/app'
import { isZh, lang } from '@/utils/const'
import { capitalize } from '@/utils'
// import { router } from '@/modules/router'

const isProduction = process.env.VITE_MODE === 'production'

let baseURL = import.meta.env.VITE_BASE_URL
if (!isProduction) {
  if (isZh) {
    baseURL = 'https://tapon-api-zh-test.joyread.cc'
  } else if (lang !== 'en') {
    baseURL = `https://tapon-api-${lang}-test.joyread.cc`
  }
}

const service = axios.create({
  baseURL,
  timeout: 30000,
})

export type IConfig = AxiosRequestConfig & {
  noMessage?: boolean
  noLoad?: boolean
}
let noMessage = false // 是否不显示消息提示

// 请求拦截
service.interceptors.request.use(
  (config: IConfig) => {
    // 防止缓存，给get请求加上时间戳
    if (config.method === 'get') config.params = { ...config.params, t: new Date().getTime() }

    // 是否不显示消息提示
    noMessage = !!config.noMessage

    // 是否显示加载中组件
    if (!config.noLoad) useAppStore().setIsLoading(true)

    config.headers = {
      ...config?.headers,
      authorization: `Bearer ${useUserStore().token}`,
      'x-lang': isZh ? 'zh' : lang,
      'z-lang': isZh ? 'zh' : lang,
      'x-platform': 'web',
    }

    if (isZh) {
      config.headers['x-lang-script'] = capitalize(lang)
    }

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

// 响应拦截
service.interceptors.response.use(
  (response: AxiosResponse) => {
    useAppStore().setIsLoading(false)
    const res = response?.data
    if (!res) {
      return Promise.reject(new Error(res.message))
    } else {
      return JSON.parse(languageTransform(JSON.stringify(res)))
    }
  },
  (error: AxiosError) => {
    useAppStore().setIsLoading(false)
    const status = error?.response?.status

    // token失效
    if (status === 401) {
      useUserStore().logout()
      useUserStore().setShowLogin(true)
    }

    if (status === 500) return Promise.reject(new Error('server error'))

    const badMessage = error?.response as any
    !noMessage && window.$message.error(languageTransform(badMessage?.data?.msg || badMessage?.data?.message))
    return Promise.reject(error)
  },
)

export default service
