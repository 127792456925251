<!--  -->
<script lang="ts" setup>
import type { ILayoutItems } from '@/types/home'

const props = defineProps<{
  name: string
  info?: ILayoutItems
  noMore?: boolean // 是否没有更多
  titleColor?: string // 标题颜色
}>()

const { t } = useI18n()
const router = useRouter()
const bookStore = useBookStore()
const localLang = ref(process.env.LANG || 'en')

const goToPage = () => {
  zaq('home-morecomponents-click', {
    component_name: props.name,
    component_id: props.info?.id,
  })
  bookStore.setMoreTypeData(props?.info as ILayoutItems)
  router.push('/typePages')
}
</script>

<template>
  <div flex items-center justify-between my-24px>
    <div flex items-center>
      <img w-18px mr-2 :src="localImage(`${localLang === 'ar' ? 'title-icon-ar.png' : 'title-icon.png'}`)" alt="" />
      <h3 text-24px font-semibold :style="`color: ${titleColor}`">
        {{ props.name }}
      </h3>
    </div>
    <a v-if="!noMore" href="/typePages" flex items-center color="#BFBFBF" text-16px @click.prevent="goToPage">
      <slot name="left">
        {{ t('more') }}
      </slot>
      <div :class="localLang === 'ar' ? 'i-carbon-chevron-left' : 'i-carbon-chevron-right'" localLang font-semibold />
    </a>
  </div>
</template>

<style lang="less" scoped></style>
