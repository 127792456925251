<script setup lang="ts">
import { asyncGetBanners, asyncGetNovels } from '@/api/home'
import type { IBanner, IBook } from '@/types/home'
import Style2 from '@/components/Home/Style-2.vue'
import Style3 from '@/components/Home/Style-3.vue'
import Style4 from '@/components/Home/Style-4.vue'
import Style5 from '@/components/Home/Style-5.vue'
import Style6 from '@/components/Home/Style-6.vue'
import Style7 from '@/components/Home/Style-7.vue'
import Style9 from '@/components/Home/Style-9.vue'
import Style10 from '@/components/Home/Style-10.vue'
import Style11 from '@/components/Home/Style-11.vue'
import Style12 from '@/components/Home/Style-12.vue'
import Style13 from '@/components/Home/Style-13.vue'
import Style16 from '@/components/Home/Style-16.vue'

const router = useRouter()
const { t } = useI18n()
const bookStore = useBookStore()
const localLang = ref(process.env.LANG || 'en')

const componentsMap: { [v: string]: any } = {
  2: Style2,
  3: Style3,
  4: Style4,
  5: Style5,
  6: Style6,
  7: Style7,
  9: Style9,
  10: Style10,
  11: Style11,
  12: Style12,
  13: Style13,
  16: Style16,
}

// 获取官网banner
const banners = ref<IBanner[]>([]) // 官网banner
const getBanners = async () => {
  const { data } = await asyncGetBanners({ type: 1 })
  if (data && data.length > 0) {
    banners.value = data.filter((v: IBanner) => v.platforms === 'pc')
  }
  zaq('home-pcbanner-view', { banners: JSON.stringify(data) })
}

// 获取猜你喜欢数据
const youLikeList = ref<IBook[]>([]) // 猜你喜欢数据
const page = ref(1) // 分页
const youLikeLoading = ref(false) // 加载状态
const showYouLikeMore = ref(true) // 是否显示猜你喜欢更多
const getGuessYouLike = async (num = 1) => {
  page.value = num
  youLikeLoading.value = true
  const { data } = await asyncGetNovels({
    page: page.value,
    per_page: 10,
  })
  youLikeList.value = [...youLikeList.value, ...data]
  showYouLikeMore.value = data.length === 10
  youLikeLoading.value = false
}

// 点击banner
const onBannerClick = (banner: IBanner) => {
  zaq('home-pcbanner-click', {
    banner_name: banner.name,
    banner_id: banner.id,
  })
  const { action, params } = banner.jsbridge
  if (['h5', 'version_update'].includes(action)) {
    window.open(params.url)
    return
  }
  if (action === 'app_page') {
    const { id, view, chapter_id } = params
    switch (view) {
      case 'category':
        router.push(`/classification?id=${id}`)
        break
      case 'recharge':
        router.push('/c')
        break
      case 'chapter_detail':
        router.push(`/bookDetail/${id}?chapter=${chapter_id}`)
        break
      case 'novel_info':
        router.push(`/bookDetail?id=${id}`)
        break
      case 'library':
        router.push('/bookshelf')
        break
      default:
        break
    }
  }
}

onMounted(async () => {
  zaq('home-pcpage-view')
  zaq('taponweb-home-view')

  await getBanners()
  await bookStore.getLayout()
  getGuessYouLike()
})
</script>

<template>
  <!-- banner -->
  <div web-container mb-4>
    <NCarousel autoplay w-full draggable :show-arrow="banners.length > 1" :show-dots="banners.length > 1">
      <img
        v-for="banner in banners"
        :key="banner.id"
        rounded-2xl
        overflow-hidden
        w-full
        h-360px
        object-cover
        cursor-pointer
        :src="banner.image"
        @click.stop="onBannerClick(banner)"
      />
      <template #arrow="{ prev, next }">
        <button type="button" class="curtom-arrow-btn left absolute top-1/2 -translate-y-1/2" @click="prev">
          <div :class="localLang === 'ar' ? 'i-carbon-chevron-right' : 'i-carbon-chevron-left'" />
        </button>
        <button type="button" class="curtom-arrow-btn right absolute top-1/2 -translate-y-1/2" @click="next">
          <div :class="localLang === 'ar' ? 'i-carbon-chevron-left' : 'i-carbon-chevron-right'" />
        </button>
      </template>
      <template #dots="{ total, currentIndex, to }">
        <ul flex mx-auto absolute gap-16px bottom-20px class="custom-dots left-1/2 -translate-x-1/2">
          <li
            v-for="index of total"
            :key="index"
            inline-block
            w-16px
            h-4px
            rounded-full
            cursor-pointer
            class="custom-dot-li"
            :class="{ ['w-40px bg-white']: currentIndex === index - 1 }"
            @click="to(index - 1)"
          />
        </ul>
      </template>
    </NCarousel>
  </div>

  <!-- 不同style对于不同组件 -->
  <keep-alive>
    <section
      v-for="item in bookStore.layouts"
      :key="item.id"
      overflow-hidden
      mb-32px
      :class="[3, 11].includes(item.style) ? '' : 'web-container'"
    >
      <template v-if="componentsMap[item.style]">
        <Title v-if="![3, 11, 16].includes(item.style)" :name="item.name" :info="item" />
        <component :is="componentsMap[item.style]" :info="item" />
      </template>
    </section>
  </keep-alive>

  <!-- 无限刷 -->
  <section v-if="!useAppStore().isLoading || youLikeLoading" web-container overflow-hidden mb-32px>
    <Title :name="t('youMayAlsoLike')" no-more />
    <section flex flex-wrap gap-24px>
      <a
        v-for="book in youLikeList"
        :key="book.novel_id"
        :href="`/bookDetail?id=${book.novel_id}`"
        w-588px
        flex-none
        cursor-pointer
        flex
        gap-16px
        rounded-lg
        class="group"
      >
        <div w-104px h-139px overflow-hidden rounded-lg flex-none>
          <img img-hover :src="book.avatar" />
        </div>
        <div overflow-hidden w-full>
          <h3 text-18px font-medium line-clamp-1 mt-8px group-hover:text-primary>
            {{ book.title }}
          </h3>
          <div mt-8px line-clamp-3 min-h-60px color="#8C8C8C">
            {{ book?.summary }}
          </div>
          <div w-full flex items-center justify-between mt-8px color="#BFBFBF">
            <div flex items-center>
              <template v-if="book?.author">
                <div flex items-center overflow-hidden>
                  <NAvatar
                    w-16px
                    h-16px
                    mr-8px
                    round
                    size="small"
                    objectFit="cover"
                    :src="book?.author_avatar || localImage('avator-icon.png')"
                  />
                  <span line-clamp-1>{{ book?.author }}</span>
                </div>
                <div class="text-[#E5E5E5] mx-16px">|</div>
              </template>
              <div flex items-center>
                <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
                {{ book?.category?.name }}
              </div>
            </div>
            <div flex items-center>
              <img w-16px h-16px rounded-full mr-8px :src="localImage('see-icon.png')" alt="" />
              <span>{{ book?.display_num }}</span>
            </div>
          </div>
        </div>
      </a>
    </section>
    <div
      v-if="showYouLikeMore"
      flex
      justify-center
      items-center
      mt-65px
      cursor-pointer
      class="text-[#BFBFBF] youlike-foot"
      hover="text-primary"
      @click="getGuessYouLike(page + 1)"
    >
      <div w-24px h-24px mr-2 />
      <span>{{ t('seeMore') }}</span>
    </div>
  </section>
</template>

<!-- 选择layout -->
<!-- <route lang="yaml">
meta:
  layout: home
</route> -->

<style lang="less" scope>
button.curtom-arrow-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 76px;
  color: #fff;
  background-color: transparent;
  border-width: 0;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &.left {
    left: 0;
    border-radius: 0 8px 8px 0;
  }

  &.right {
    right: 0;
    border-radius: 8px 0 0 8px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active div {
    transform: scale(1.3);
    transform-origin: center;
  }
}

.custom-dot-li {
  background-color: rgba(255, 255, 255, 0.4);
  transition:
    width 0.3s,
    background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.youlike-foot {
  & > div {
    background: url('../assets/images/down-icon.png') no-repeat center center;
    background-size: 100% 100%;
  }

  &:hover {
    & > div {
      background-image: url('../assets/images/down-active-icon.png');
    }
  }
}
</style>
