<script setup lang="ts">
import { asyncGetServerTime } from '@/api/user'

// const props = defineProps<{
//   title?: string
//   startTime: number
//   endTime: number
//   color?: string

// }>()

const props = withDefaults(
  defineProps<{
    title?: string
    startTime: number
    endTime: number
    color?: string
  }>(),
  {
    title: undefined,
    startTime: 0,
    endTime: 0,
    color: '#F92500',
  },
)

const { t } = useI18n()
const bookStore = useBookStore()
const bg = ref(props.color)
const localLang = ref(process.env.LANG || 'en')
if (localLang.value === 'ar') {
  bg.value = '#fd6b0a'
}

// const bg = computed(() => {
//   return props.color
// })

const { days, hours, minutes, seconds, currentTime, countDown } = useCountdownFn()

const serverTime = ref(0) // 服务器时间

async function getServerTime() {
  const { data } = await asyncGetServerTime()
  serverTime.value = data.timestamp || 0
}

const countdownTitle = ref('') // 活动标题

const getActiveTitle = () => {
  const now = serverTime.value
  const start = props.startTime
  const end = props.endTime
  if (now < start) {
    countdownTitle.value = t('startIn')
    countDown(start - now, () => {
      if (now < end) {
        onActiveEnd(end, now)
      }
    })
  } else if (now < end) {
    onActiveEnd(end, now)
  }
}

function onActiveEnd(end: number, now: number) {
  countdownTitle.value = t('closeIn')
  countDown(end - now, () => {
    bookStore.getLayout()
  })
}

watch(
  [props, serverTime],
  () => {
    if (serverTime.value > 0) {
      getActiveTitle()
    }
  },
  {
    deep: true,
    immediate: true,
  },
)

onMounted(async () => {
  await getServerTime()
  // getActiveTitle()
})
</script>

<template>
  <div v-if="currentTime > 0" :class="`flex items-center text-16px color-${bg}`">
    {{ props.title ?? countdownTitle }}
    <template v-if="days >= 4">
      <span class="!ml-12px">{{ days < 10 ? `0${days}` : days }}</span>
      D
    </template>
    <span>{{ hours }}</span>
    :
    <span>{{ minutes }}</span>
    :
    <span>{{ seconds }}</span>
  </div>
</template>

<style lang="less" scoped>
span {
  width: 25px;
  height: 25px;
  background: v-bind(bg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 8px;
  font-size: 14px;
}
</style>
