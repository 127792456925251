{
  "RemoveAllHistory": "열람내역 전체삭제",
  "SignInViewBooks": "로그인 후 회원님이 서재에 추가한 작품들을 확인하실 수 있습니다",
  "about": "정보",
  "accountDontExist": "존재하지 않는 아이디입니다.\n정확한 사용자 아이디 또는 연동 메일로 로그인을 시도해 주세요.\n",
  "active": "{x} OFF",
  "add": "관심",
  "addToLibrary": "서재에 추가",
  "addedToLibrary": "서재에 추가되었습니다",
  "addedToLibraryBtn": "관심작품",
  "appliedOff": "{x} OFF 선택",
  "areYou": "선택하신 작품을 삭제하시겠습니까?",
  "areYouRemoveRecords": "모든 열람 내역을 삭제하시겠습니까? 삭제 후에는 취소할 수 없습니다.",
  "areYouSure": "로그아웃하시겠습니까?",
  "autoUnlock": "다음회차 자동결제하기",
  "back": "이전",
  "background": "배경색상",
  "balance": "계정 잔액",
  "beginReading": "작품 고르기",
  "betterPlans": "로그인 후 앱보다 파격적인 할인 확인가능",
  "bonus": "보너스 캐시",
  "booksInTotal": "총 {x}개 작품",
  "cancel": "취소",
  "cantactUs": "문의",
  "card_holder_name": "카드 명의자",
  "card_info": "카드 정보",
  "card_number": "카드 번호",
  "cards": "{x} OFF 쿠폰",
  "chapter_x": "제{x}화",
  "cheapX": "Up to {x}% ",
  "checkout": "결제",
  "chooseYourAccount": "충전할 계정을 선택해 주세요.",
  "chooseYourPlan": "충전금액 선택",
  "choose_a_coupon": "쿠폰 선택",
  "choose_a_package": "상품 선택",
  "closeIn": "시작전:",
  "coins": "캐시",
  "coinsUnlockStories": "캐시와 보너스 캐시으로 회차 잠금해제가 가능합니다.",
  "coinsUnlockThisChapter": " {x} 캐시/보너스 캐시으로 본 회차 구매하기",
  "coins_added": "캐시가 충전되었습니다",
  "coins_free": "{x} 캐시 + {x}보너스 캐시",
  "completed": "완결",
  "confirm": "확인",
  "confirm_your_account": "본인계정 확인",
  "continue": "이어보기",
  "continueReadMore": "후속 스토리에 대한 궁금증 해소 및 취향 저격 작품들을 탭북에서 만나보세요.",
  "continue_topping_up": "충전 계속하기",
  "countBooks": "총 {x}개 작품",
  "countViews": "조회수 {x}",
  "credit_or_debit_card": "신용카드 또는 체크카드",
  "details": "총 캐시/보너스 캐시",
  "discountOffApplied": "쿠폰 적용 안함",
  "discountOffInactive": "사용 가능한 쿠폰 없음",
  "done": "삭제 완료",
  "edit": "설정",
  "enterBookName": "작품명, 작가명을 입력하세요.",
  "enterYouIDorEmail": "사용자 ID 또는 연동 메일 입력",
  "enter_card_holder_name": "카드 명의자 이름을 입력해주세요",
  "enteryourId": "사용자 ID를 입력해 주세요",
  "expiration_date": "유효 기간",
  "expiredSeeTime": "존재하지 않는 상품입니다. 다음 기회에 만나요~",
  "expiresAtDate": "{x} 까지",
  "failed": "로그인 실패",
  "failedToLoad": "로딩 실패",
  "featuredStories": "인생작 리스트",
  "followUs": "팔로우하기",
  "fontSize": "글자크기",
  "for_all_top_up_packages": "모든 충전 금액 사용 가능",
  "for_x_only": "{x} 충전에만 사용 가능",
  "for_x_or_less": "{x} 및 그 이하 충전 시 사용 가능",
  "for_x_or_more": "{x} 및 그 이상 충전 시 사용 가능",
  "free_coins": "보너스 캐시",
  "genres": "장르",
  "genresTitle": "장르",
  "getBouns": "추가증정 {x}보너스 캐시",
  "getMore": "{x} 추가 충전 찬스",
  "getMoreCoins": "캐시 충전 후 즐독하기",
  "getTheApp": "앱 다운로드하기",
  "get_free_coins": "보너스 캐시 받기",
  "goToAppTip": "앱으로 이동",
  "gotoTaponApp": "탭북 앱으로 이동",
  "history": "내역",
  "home": "홈",
  "hot": "핫",
  "inactiveOff": "{x} OFF 이용불가",
  "including_free_coins": "+ {x} 보너스 캐시",
  "iosCanAppleId": "애플 계정으로 회원가입하셨다면 사용자 ID로 로그인 가능",
  "lastChapter": "이전화",
  "latestUpdate": "최신 회차",
  "legal": "약관",
  "library": "내 서재",
  "limitedTimeOffer": "한정 특가 {x}% 세일",
  "limited_time_offer": "기간 한정 증정",
  "limited_time_offers": "타임특가",
  "list": "목록",
  "loggedIn": "로그인 성공",
  "loginMoreStory": "로그인 후 즐독해 보세요",
  "loginTip": "로그인 시 {a}과 {b}에 \n 동의하는 것으로 묵인됩니다.",
  "more": "더보기",
  "moreBonusTip": "인앱결제보다 더 많은 보너스 캐시 Get 하세요",
  "moreOptions": "선택사항",
  "moreThanApp": "앱 대비 {x}% 추가 증정",
  "need_pay": "결제금액",
  "netWorkError": "서버에 연결할 수 없음.",
  "newRelease": "뉴",
  "next": "다음 페이지",
  "nextChapter": "다음화",
  "noActiveCoupons": "쿠폰",
  "noContent": "준비중인 콘텐츠",
  "noFindServe": "죄송합니다. 현재 해당 국가 또는 지역에서 서비스를 이용할 수 없습니다.",
  "noResultsFound": "검색 결과가 없네요 ㅠㅠ",
  "notFoundUser": "잘못된 아이디입니다, 재입력해 주세요.",
  "notNow": "나중에",
  "notUseAnyCoupons": "할인",
  "okay": "확인",
  "ongoing": "연재중",
  "or": "또는",
  "order": "순서",
  "originalPrice": "결제금액",
  "otherOptions": "기타 로그인 방식",
  "other_plans": "일일 추천",
  "pay": "충전",
  "pay_now": "결제하기",
  "pay_with_apple_pay": "Apple Pay로 결제",
  "pay_with_credit": "신용카드 또는 체크카드로 결제",
  "pay_with_google_pay": "Google Pay로 결제",
  "paymentMethod": "지불방식",
  "payment_successful": "결제성공",
  "platformTip": "{x} 로그인",
  "previous": "이전 페이지",
  "price": "결제금액",
  "privacyPolicy": "개인정보 보호정책",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "충전 실패",
  "purchaseSucceeded": "충전 성공",
  "read": "보기",
  "read2": "열람",
  "readItInTheApp": "앱으로 간편 열람GO",
  "readItNow": "첫회보기",
  "readTheBookInLibrary": "관심작 열람하기",
  "rec": "추천",
  "rechargeCoupon": "정상가",
  "rechargeSave": "결제정보",
  "refresh": "새로고침",
  "remove": "삭제",
  "resources": "리소스",
  "saveCoupon": "{x}할인",
  "save_my_card_for_future": "카드 저장하고 다음번에 간편하게 결제하기",
  "save_x": "타임딜 {x} 즉시 할인",
  "security_code": "보안 코드",
  "seeMore": "더보기",
  "select_payment_method": "결제수단 선택",
  "signID": "ID로 로그인",
  "signIn": "로그인",
  "signIntoTapon": "탭북 로그인",
  "signOut": "로그아웃",
  "signOutCancelledShep": "로그아웃 후 더 이상 충전할 수 없습니다. ",
  "signUpAppleID": "애플아이디로 회원가입하신 회원님께서는 사용자 ID로 로그인 가능합니다. {value}하여, '나'->프사 주변에서 사용자 ID 확인 가능. ",
  "startIn": "마감전:",
  "start_reading_on_tapon": "Tapon App으로 보기",
  "switch_account": "계정 변경",
  "synopsis": "작품 소개",
  "tags": "해시태그",
  "termsOfUse": "이용약관",
  "title": "탭북 — 당신만의 소설 천국, 지루함은 Bye~, 매일매일 새로운 스토리를 만나보세요!",
  "toCreate": "작가 코너",
  "topCount": "Top count",
  "topUp": "공식 충전센터",
  "topUpBtn": "충전",
  "tryAnother": "다른 작품을 검색해 보세요~",
  "unknownError": "알 수 없는 오류",
  "unlockNow": "결제하기",
  "use_new_card": "새 카드로 변경",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "사용자 ID",
  "username": "닉네임",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "탭북 에 오신 것을 환영합니다.",
  "x_coins": "{x} 캐시",
  "x_inactive_coupons": "이용불가 쿠폰: {x}",
  "youIdIncorrect": "존재하지 않는 아이디입니다",
  "youMayAlsoLike": "맞춤 추천",
  "you_will_get": "합계"
}