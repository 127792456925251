{
  "RemoveAllHistory": "إزالة جميع السجلات",
  "SignInViewBooks": "تسجيل الدخول لمشاهدة كل كتبك المفضلة في المكتبة",
  "about": "من نحن",
  "accountDontExist": "الحساب غير مسجل لدينا. جرب حسابا آخر. يمكنك تسجيل الدخول بالبريد الإلكتروني أو اسم المستخدم.",
  "active": "{x}-خصم",
  "add": "إضافة إلى المكتبة",
  "addToLibrary": "إضافة إلى المكتبة",
  "addedToLibrary": "تمت الإضافة إلى المكتبة",
  "addedToLibraryBtn": "تمت الإضافة إلى المكتبة",
  "appliedOff": "{x} تم تطبيق الخصم",
  "areYou": "هل أنت متأكد من إزالة جميع الكتب المختارة؟",
  "areYouRemoveRecords": "هل أنت متأكد أنك تريد حذف جميع السجلات؟ لا يمكن التراجع عن هذا الإجراء.",
  "areYouSure": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  "autoUnlock": "الفتح التلقائي",
  "back": "الرجوع",
  "background": "الخلفية",
  "balance": "الرصيد",
  "beginReading": "بدء القراءة",
  "betterPlans": "خطط أفضل متاحة بعد تسجيل الدخول",
  "bonus": "النقاط المجانية",
  "booksInTotal": "{x} إجمالي مجموع الكتب",
  "cancel": "إلغاء",
  "cantactUs": "اتصل بنا",
  "card_holder_name": "اسم حامل البطاقة",
  "card_info": "معلومات البطاقة",
  "card_number": "رقم البطاقة",
  "cards": "{x}-كوبون الخصم",
  "chapter_x": "الفصل {x}",
  "cheapX": "حتى نسبة {x}٪",
  "checkout": "إتمام عملية الشراء",
  "chooseYourAccount": "اختر حسابك",
  "chooseYourPlan": "اختر خطتك",
  "choose_a_coupon": "اختر قسيمة",
  "choose_a_package": "اختر حزمة",
  "closeIn": "يغلق في:",
  "coins": "النقاط",
  "coinsUnlockStories": "يمكن استخدام النقاط والنقاط المجانية لفتح القصص",
  "coinsUnlockThisChapter": "استخدم {x} من النقاط/ النقاط المجانية لفتح هذا الفصل",
  "coins_added": "تمت إضافة النقاط إلى حسابك",
  "coins_free": "{x} نقاط + {x} نقاط مجانية",
  "completed": "تم الانتهاء",
  "confirm": "تأكد من",
  "confirm_your_account": "تأكد من حسابك",
  "continue": "الاستمرار",
  "continueReadMore": "الاستمرار في القراءة لاكتشاف المزيد من القصص الرائعة في \"روايات بين يديك\"",
  "continue_topping_up": "الاستمرار في إعادة الشحن",
  "countBooks": "{x} الكتب",
  "countViews": "{x} المشاهدات",
  "credit_or_debit_card": "بطاقة الائتمان / بطاقة الخصم المباشر",
  "details": "النقاط",
  "discountOffApplied": "لا تستخدم أي قسائم",
  "discountOffInactive": "لا يوجد قسائم نشطة",
  "done": "تم",
  "edit": "تعديل",
  "enterBookName": "أدخل اسم الكتاب /المؤلف",
  "enterYouIDorEmail": "الرجاء إدخال معرف المستخدم أو البريد الإلكتروني الصحيح",
  "enter_card_holder_name": "أدخل اسم حامل البطاقة هنا",
  "enteryourId": "أدخل معرف المستخدم هنا",
  "expiration_date": "تاريخ الصلاحية",
  "expiredSeeTime": "انتهت الصلاحية. نراك في المرة القادمة~",
  "expiresAtDate": "تنتهي في {x}",
  "failed": "فشل",
  "failedToLoad": "فشل في التحميل",
  "featuredStories": "قصص مميزة",
  "followUs": "تابعونا",
  "fontSize": "حجم الخط",
  "for_all_top_up_packages": "لكافة باقات الشحن",
  "for_x_only": "حصريا لـ {x}",
  "for_x_or_less": "لـ {x} أو أقل",
  "for_x_or_more": "لـ {x} أو أكثر",
  "free_coins": "العملات المجانية",
  "genres": "المجالات",
  "genresTitle": "المجالات",
  "getBouns": "احصل مجانًا على: {x} نقاط مجانية",
  "getMore": "عرض إضافي لفترة محدودة {x}",
  "getMoreCoins": "احصل على المزيد من النقاط",
  "getTheApp": "تحميل التطبيق",
  "get_free_coins": "احصل على نقاط مجانية",
  "goToAppTip": "الذهاب إلى التطبيق",
  "gotoTaponApp": "انتقل إلى التطبيق",
  "history": "التاريخ",
  "home": "الصفحة الرئيسية",
  "hot": "الأكثر شهرة",
  "inactiveOff": "خصم {x} - غير فعّال",
  "including_free_coins": "+ {x} نقاط مجانية",
  "iosCanAppleId": " يمكن لمستخدمي iOS تسجيل الدخول بحساب Apple الخاص بهم",
  "lastChapter": "الفصل الأخير",
  "latestUpdate": "آخر تحديث",
  "legal": "قانوني",
  "library": "المكتبة",
  "limitedTimeOffer": "عرض لفترة محدودة: خصم {x}%",
  "limited_time_offer": "عرض لفترة محدودة",
  "limited_time_offers": "عروض لفترة محدودة",
  "list": "القائمة",
  "loggedIn": "تم التسجيل الدخول",
  "loginMoreStory": "سجّل الدخول لقراءة كل القصص الرائعة",
  "loginTip": "بتسجيلك الدخول، أنت توافق على {a} و {b}.",
  "more": "المزيد",
  "moreBonusTip": "يمكنك الحصول على عدد أكبر من النقاط المجانية هنا مقارنةً بالتطبيق.",
  "moreOptions": "خيارات آخرى",
  "moreThanApp": "حسم %{x} أكثر من التطبيق",
  "need_pay": "السعر",
  "netWorkError": "الاتصال بالشبطة ضعيف",
  "newRelease": "إصدار جديد",
  "next": "التالي",
  "nextChapter": "الفصل التالي",
  "noActiveCoupons": "قسيمة",
  "noContent": "لا يوجد محتوى",
  "noFindServe": "عذرًا، خدمتنا ليست متوفرة في بلدك/ منطقتك ",
  "noResultsFound": "لا توجد نتائج",
  "notFoundUser": "المستخدم غير مسجل لدينا، برجاء إدخال بيانات صحيحة",
  "notNow": "ليس الآن",
  "notUseAnyCoupons": "حفظ",
  "okay": "حسنًا",
  "ongoing": "جاري",
  "or": "أو",
  "order": "ترتيب",
  "originalPrice": "دفع",
  "otherOptions": "خيارات إضافية",
  "other_plans": "خطط أخرى",
  "pay": "شحن",
  "pay_now": "الدفع الآن",
  "pay_with_apple_pay": "الدفع باستخدام Apple Pay",
  "pay_with_credit": "الدفع باستخدام بطاقة الائتمان / الخصم",
  "pay_with_google_pay": "الدفع باستخدام Google Pay",
  "paymentMethod": "طرق الدفع",
  "payment_successful": "تمت عملية الدفع بنجاح",
  "platformTip": "سجل الدخول باستخدام {x}",
  "previous": "السابق",
  "price": "السعر",
  "privacyPolicy": "سياسة الخصوصية",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "فشل في عملية الشراء",
  "purchaseSucceeded": "تمت إعادة الشحن",
  "read": "قراءة",
  "read2": "قراءة",
  "readItInTheApp": "القراءة في التطبيق",
  "readItNow": "القراءة الآن",
  "readTheBookInLibrary": "قراءة الكتب في مكتبتك",
  "rec": "تو",
  "rechargeCoupon": "السعر الأصلي",
  "rechargeSave": "التفاصيل",
  "refresh": "تحديث",
  "remove": "إزالة",
  "resources": "الموارد",
  "saveCoupon": "حفظ {x}",
  "save_my_card_for_future": "حفظ البطاقة للمدفوعات المستقبلية",
  "save_x": "حفظ {x}",
  "security_code": "رمز الأمان",
  "seeMore": "المزيد",
  "select_payment_method": "اختر طريقة الدفع",
  "signID": "تسجيل الدخول باستخدام معرف المستخدم",
  "signIn": "تسجيل الدخول",
  "signIntoTapon": "تسجيل الدخول إلى \"روايات بين يديك\"",
  "signOut": "تسجيل الخروج",
  "signOutCancelledShep": "سيتم إلغاء الشحن إذا قمت بتسجيل الخروج",
  "signUpAppleID": "المستخدمون الذين سجلوا باستخدام Apple ID يمكنهم تسجيل الدخول بمعرف المستخدم {value} والتحقق من معرف المستخدم بجانب صورة الملف الشخصي في \"حسابي\".",
  "startIn": "البدء في:",
  "start_reading_on_tapon": "بدء القراءة في \"روايات بين يديك\"",
  "switch_account": "تبديل الحساب",
  "synopsis": "الملخص",
  "tags": "العلامات",
  "termsOfUse": "شروط الاستخدام",
  "title": "\"روايات بين يديك\" - مكتبة روايات إليكترونية بكبسة زر. القراءة الرقمية أسلوب حياة",
  "toCreate": "لإنشاء",
  "topCount": "العدد",
  "topUp": "مركز الشحن",
  "topUpBtn": " الشحن",
  "tryAnother": "جرب كتبا أخرى",
  "unknownError": "خطأ غير معروف",
  "unlockNow": "افتحه الآن",
  "use_new_card": "استخدم بطاقة جديدة",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "معرف المستخدم",
  "username": "اسم المستخدم",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "مرحبًا بك في \"روايات بين يديك\"",
  "x_coins": "{x} النقاط",
  "x_inactive_coupons": "{x} قسائم غير نشطة",
  "youIdIncorrect": "المستخدم غير موجود",
  "youMayAlsoLike": "قد يعجبك أيضًا",
  "you_will_get": "ستحصل على"
}