{
  "RemoveAllHistory": "刪除所有記錄",
  "SignInViewBooks": "登錄後，在書架內就可以查看所有您收藏的書籍",
  "about": "關於",
  "accountDontExist": "未找到對應用戶\n\n請換個應用綁定郵箱或用戶id登錄",
  "active": "{x} OFF",
  "add": "添加",
  "addToLibrary": "加入書架",
  "addedToLibrary": "已成功加入書架",
  "addedToLibraryBtn": "已加入書架",
  "appliedOff": "已選{x} OFF",
  "areYou": "確定刪除所選書籍",
  "areYouRemoveRecords": "確認刪除您所有的歷史記錄？\n您無法撤消此操作。",
  "areYouSure": "確定退出嗎？",
  "autoUnlock": "自動解鎖下一章",
  "back": "返回",
  "background": "背景",
  "balance": "賬戶余額",
  "beginReading": "去找書",
  "betterPlans": "登錄後，充值比應用更優惠",
  "bonus": "贈幣",
  "booksInTotal": "共{x}書",
  "cancel": "取消",
  "cantactUs": "聯繫我們",
  "card_holder_name": "持卡人姓名",
  "card_info": "銀行卡信息",
  "card_number": "卡號",
  "cards": "{x} OFF 優惠券",
  "chapter_x": "第{x}章",
  "cheapX": "最高送{x}%",
  "checkout": "支付",
  "chooseYourAccount": "請選擇要充值的賬戶",
  "chooseYourPlan": "選擇套餐",
  "choose_a_coupon": "選擇優惠券",
  "choose_a_package": "選擇商品",
  "closeIn": "距結束",
  "coins": "書幣",
  "coinsUnlockStories": "書幣、贈幣可用於解鎖章節",
  "coinsUnlockThisChapter": "購買本章需要 {x} 書幣/贈幣",
  "coins_added": "書幣已到賬",
  "coins_free": "{x} 幣 + {x} 贈幣",
  "completed": "已完結",
  "confirm": "確認",
  "confirm_your_account": "賬號確認",
  "continue": "繼續閱讀",
  "continueReadMore": "繼續閱讀更多章節，並發現開讀小說中更多精彩故事",
  "continue_topping_up": "繼續充值",
  "countBooks": "{x}本書",
  "countViews": "{x}閱讀",
  "credit_or_debit_card": "信用卡或借記卡",
  "details": "總幣/券",
  "discountOffApplied": "不使用優惠券",
  "discountOffInactive": "無可用優惠券",
  "done": "刪除成功",
  "edit": "編輯",
  "enterBookName": "輸入書名/作者",
  "enterYouIDorEmail": "輸入id或應用綁定郵箱",
  "enter_card_holder_name": "請輸入持卡人姓名",
  "enteryourId": "請輸入您的用戶ID登錄",
  "expiration_date": "有效期",
  "expiredSeeTime": "充值商品已過期，下次來早點哦~",
  "expiresAtDate": "{x} 到期",
  "failed": "登錄失敗",
  "failedToLoad": "加載失敗",
  "featuredStories": "精品書單",
  "followUs": "關注我們",
  "fontSize": "字號",
  "for_all_top_up_packages": "所有充值檔位可用",
  "for_x_only": "僅限 {x} 檔位可用",
  "for_x_or_less": "{x} 及以下檔位可用",
  "for_x_or_more": "{x} 及以上檔位可用",
  "free_coins": "贈幣",
  "genres": "分類",
  "genresTitle": "類別",
  "getBouns": "贈送 {x} 贈幣",
  "getMore": "限時加贈{x}",
  "getMoreCoins": "購買書幣閱讀",
  "getTheApp": "下載APP",
  "get_free_coins": "贈幣免費",
  "goToAppTip": "前往應用",
  "gotoTaponApp": "前往開讀小說應用",
  "history": "歷史",
  "home": "首頁",
  "hot": "最熱",
  "inactiveOff": "{x} OFF不可用",
  "including_free_coins": "+ {x} 贈幣",
  "iosCanAppleId": "蘋果賬號註冊用戶可使用用戶id登錄",
  "lastChapter": "上一章",
  "latestUpdate": "最新章節",
  "legal": "用戶須知",
  "library": "書架",
  "limitedTimeOffer": "限時降價{x}%",
  "limited_time_offer": "限時贈送",
  "limited_time_offers": "限時特惠",
  "list": "目錄",
  "loggedIn": "登錄成功",
  "loginMoreStory": "登錄後，訪問更多精彩故事",
  "loginTip": "點擊登錄代表同意\n\n{a}和{b}",
  "more": "更多",
  "moreBonusTip": "在這裡獲得比APP更多贈幣",
  "moreOptions": "顯示選項",
  "moreThanApp": "比App多{x}%",
  "need_pay": "需支付",
  "netWorkError": "網絡連接錯誤",
  "newRelease": "最新",
  "next": "下一頁",
  "nextChapter": "下一章",
  "noActiveCoupons": "優惠券",
  "noContent": "沒有內容",
  "noFindServe": "抱歉，我們的服務尚未在您的國家/地區提供。",
  "noResultsFound": "啊呀，沒有找到您要看的書",
  "notFoundUser": "未找到該用戶，請重新輸入",
  "notNow": "以後再說",
  "notUseAnyCoupons": "優惠",
  "okay": "知道了",
  "ongoing": "連載中",
  "or": "或",
  "order": "排序",
  "originalPrice": "支付",
  "otherOptions": "其他登錄方式",
  "other_plans": "日常推薦",
  "pay": "充值",
  "pay_now": "立即支付",
  "pay_with_apple_pay": "點擊使用蘋果支付",
  "pay_with_credit": "使用信用卡或借記卡支付",
  "pay_with_google_pay": "點擊使用谷歌支付",
  "paymentMethod": "支付方式",
  "payment_successful": "支付成功",
  "platformTip": "{x}登錄",
  "previous": "上一頁",
  "price": "價格",
  "privacyPolicy": "隱私政策",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "創建訂單失敗",
  "purchaseSucceeded": "充值成功",
  "read": "閱讀",
  "read2": "閱讀",
  "readItInTheApp": "去APP隨時讀",
  "readItNow": "立即閱讀",
  "readTheBookInLibrary": "閱讀您收藏的書籍",
  "rec": "推薦",
  "rechargeCoupon": "原價",
  "rechargeSave": "明細",
  "refresh": "刷新",
  "remove": "刪除",
  "resources": "資源",
  "saveCoupon": "優惠{x}",
  "save_my_card_for_future": "保存我的卡以備將來付款",
  "save_x": "限時立減 {x}",
  "security_code": "安全碼",
  "seeMore": "查看更多",
  "select_payment_method": "選擇支付方式",
  "signID": "用戶ID登錄",
  "signIn": "登錄",
  "signIntoTapon": "登錄開讀小說",
  "signOut": "退出",
  "signOutCancelledShep": "退出該賬戶後，無法繼續充值啦",
  "signUpAppleID": "使用蘋果賬號註冊用戶可使用用戶ID進行登錄\n前往應用，在“我的”-頭像旁邊查看您的用戶ID",
  "startIn": "距開始",
  "start_reading_on_tapon": "去Tapon App閱讀",
  "switch_account": "切換賬號",
  "synopsis": "簡介",
  "tags": "標籤",
  "termsOfUse": "用戶協議",
  "title": "開讀小說——你的小說世界，為你的生活增添更多精彩故事",
  "toCreate": "作家專區",
  "topCount": "count",
  "topUp": "充值中心",
  "topUpBtn": "充值",
  "tryAnother": "來試試別的書~",
  "unknownError": "未知錯誤",
  "unlockNow": "立即解鎖",
  "use_new_card": "使用別的卡",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "用戶ID",
  "username": "用戶名稱",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "歡迎來到開讀小說",
  "x_coins": "{x} 幣",
  "x_inactive_coupons": "{x} 張不可用優惠券",
  "youIdIncorrect": "用戶不存在",
  "youMayAlsoLike": "為你推薦",
  "you_will_get": "獲得"
}