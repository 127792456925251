<!--  -->
<script setup lang="ts">
import { PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/const'

const userStore = useUserStore()
const { t } = useI18n()

const localLang = ref('en')
localLang.value = process.env.LANG || 'en'

const update = (flag: boolean) => {
  userStore.setShowLogin(flag)
}
</script>

<template>
  <NModal v-model:show="userStore.showLogin" :onUpdate:show="update" :mask-closable="false">
    <div w-658px z-10 bg-white overflow-hidden rounded-xl flex>
      <img w-251px flex-none :src="localImage('login-bg.png')" alt="" />
      <div flex-1 px-24px py-40px flex flex-col items-center justify-center relative>
        <iconpark-icon
          absolute
          right-16px
          top-16px
          cursor-pointer
          width="24"
          height="24"
          color="#8c8c8c"
          name="guanbi"
          @click="update(false)"
        />
        <img h-48px :src="localImage(`logo/pay-logo-${localLang}.png`)" alt="" />
        <h2 mt-24px mb-1 text-24px font-semibold>
          {{ t('welcomeAppnameTip') }}
        </h2>
        <p mb-32px text-16px font-normal color="#8C8C8C">
          {{ t('loginMoreStory') }}
        </p>
        <button
          w-full
          h-45px
          rounded-xl
          bg-white
          flex
          justify-center
          items-center
          transition
          border="~ solid #E5E5E5"
          hover="border-primary"
          @click="userStore.login('google')"
        >
          <img w-24px h-24px mr-4 :src="localImage('google.png')" alt="google" />
          <p text-16px w-200px color="#595959">
            {{ t('platformTip', { x: 'Google' }) }}
          </p>
        </button>
        <button
          w-full
          h-45px
          my-16px
          rounded-xl
          bg-white
          flex
          justify-center
          items-center
          transition
          border="~ solid #E5E5E5"
          hover="border-primary"
          @click="userStore.login('facebook')"
        >
          <img w-24px h-24px mr-4 :src="localImage('facebook.png')" alt="facebook" />
          <p text-16px w-200px color="#595959">
            {{ t('platformTip', { x: 'Facebook' }) }}
          </p>
        </button>
        <i18n-t keypath="loginTip" text-center mt-30px whitespace-pre-line color="#8C8C8C" tag="p">
          <template #a>
            <NA underline :href="USER_AGREEMENT" target="_blank">
              {{ t('termsOfUse') }}
            </NA>
          </template>
          <template #b>
            <NA underline :href="PRIVACY_POLICY" target="_blank">
              {{ t('privacyPolicy') }}
            </NA>
          </template>
        </i18n-t>
      </div>
    </div>
  </NModal>
</template>

<style lang="less" scoped></style>
