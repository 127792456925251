{
  "RemoveAllHistory": "Remover todo o histórico",
  "SignInViewBooks": "Faça login para visualizar todos os seus livros favoritos na Biblioteca",
  "about": "SOBRE",
  "accountDontExist": "TA conta não existe. Tente outra opção. Você pode entrar com seu e-mail ou ID de usuário.",
  "active": "{x} desconto",
  "add": "Adicionar à Biblioteca",
  "addToLibrary": "Adicionar à Biblioteca",
  "addedToLibrary": "Adicionado à Biblioteca",
  "addedToLibraryBtn": "Adicionado à Biblioteca",
  "appliedOff": "{x} desconto - Aplicado",
  "areYou": "Tem certeza que deseja remover todos os livros selecionados?",
  "areYouRemoveRecords": "Tem certeza que deseja remover todos os registros? Esta ação não pode ser desfeita.",
  "areYouSure": "Tem certeza que deseja sair?",
  "autoUnlock": "Desbloqueio automático",
  "back": "Voltar",
  "background": "Fundo",
  "balance": "Saldo",
  "beginReading": "Começar a leitura",
  "betterPlans": "Planos melhores serão disponibilizados depois que você fizer login",
  "bonus": "Moedas Grátis",
  "booksInTotal": "{x} livros no total",
  "cancel": "Cancelar",
  "cantactUs": "CONTATO",
  "card_holder_name": "Nome do titular",
  "card_info": "Informações do cartão",
  "card_number": "Número do cartão",
  "cards": "Cupom de {x} de desconto",
  "chapter_x": "Capítulo {x}",
  "cheapX": "Até {x}% grátis",
  "checkout": "Pagamento",
  "chooseYourAccount": "Escolha sua conta",
  "chooseYourPlan": "Escolha seu plano",
  "choose_a_coupon": "Escolha um cupom",
  "choose_a_package": "Escolha um pacote",
  "closeIn": "Acaba em:",
  "coins": "Moedas",
  "coinsUnlockStories": "Moedas e Moedas Grátis podem ser utilizadas para desbloquear histórias",
  "coinsUnlockThisChapter": "{x} moedas/Moedas Grátis para desbloquear este capítulo",
  "coins_added": "As Moedas foram adiciondas à sua conta",
  "coins_free": "{x} Moedas + {x} Moedas Grátis",
  "completed": "Concluído",
  "confirm": "Confirmar",
  "confirm_your_account": "Confirme sua conta",
  "continue": "CONTINUAR",
  "continueReadMore": "Continue lendo para descobrir mais histórias incríveis no TapLivros",
  "continue_topping_up": "Continue comprando",
  "countBooks": "{x} livros",
  "countViews": "{x} visualizações",
  "credit_or_debit_card": "Cartão de Débito/Crédito",
  "details": "Moedas",
  "discountOffApplied": "Não utilizar cupom",
  "discountOffInactive": "Sem cupom ativo",
  "done": "Concluído",
  "edit": "Editar",
  "enterBookName": "Insira o nome do livro/autor",
  "enterYouIDorEmail": "Insira o ID de usuário ou e-mail correto",
  "enter_card_holder_name": "Informe o nome do titular aqui",
  "enteryourId": "Insira seu ID de usuário aqui",
  "expiration_date": "Data de validade",
  "expiredSeeTime": "Expirado. Até a próxima~",
  "expiresAtDate": "Expira em {x}",
  "failed": "Falha",
  "failedToLoad": "Falha ao carregar",
  "featuredStories": "Histórias em destaque",
  "followUs": "SIGA-NOS",
  "fontSize": "Tamanho da fonte",
  "for_all_top_up_packages": "Para todos os pacotes",
  "for_x_only": "Apenas {x}",
  "for_x_or_less": "Menos de {x}",
  "for_x_or_more": "Mais de {x}",
  "free_coins": "Moedas Grátis",
  "genres": "Gêneros",
  "genresTitle": "Gêneros",
  "getBouns": "Grátis: {x} Moedas Grátis",
  "getMore": "Oferta extra tempo limitado {x}",
  "getMoreCoins": "Receba mais moedas",
  "getTheApp": "Obter o aplicativo",
  "get_free_coins": "Receba Grátis",
  "goToAppTip": "Acesse o aplicativo",
  "gotoTaponApp": "Acessar o aplicativo TapLivros",
  "history": "Histórico",
  "home": "Início",
  "hot": "Imperdível",
  "inactiveOff": "{x} desconto - Inativo",
  "including_free_coins": "+ {x} Moedas Grátis",
  "iosCanAppleId": "Usuários do iOS podem fazer login com o ID Apple.",
  "lastChapter": "Último capítulo",
  "latestUpdate": "Atualizações mais recentes",
  "legal": "AVISO LEGAL",
  "library": "Biblioteca",
  "limitedTimeOffer": "Oferta relâmpago: {x}% de desconto",
  "limited_time_offer": "Oferta por tempo limitado",
  "limited_time_offers": "Ofertas por tempo limitado",
  "list": "Lista",
  "loggedIn": "Sessão iniciada",
  "loginMoreStory": "Entre para ler nossas histórias maravilhosas",
  "loginTip": "Ao fazer login, você concorda com nossos {a} e {b}.",
  "more": "Mais",
  "moreBonusTip": "Você pode receber mais Moedas Grátis aqui que no aplicativo",
  "moreOptions": "Mais opções",
  "moreThanApp": "{x}% a mais no aplicativo",
  "need_pay": "Preço",
  "netWorkError": "Conexão de rede instável",
  "newRelease": "Novo lançamento",
  "next": "Próximo",
  "nextChapter": "Próximo capítulo",
  "noActiveCoupons": "Cupom",
  "noContent": "Nenhum conteúdo",
  "noFindServe": "Lamentamos. Nosso serviço ainda não está disponível em seu país/região.",
  "noResultsFound": "Nenhum resultado foi encontrado.",
  "notFoundUser": "Usuário não encontrado Insira-o novamente.",
  "notNow": "Agora não",
  "notUseAnyCoupons": "Desconto",
  "okay": "OK",
  "ongoing": "Em andamento",
  "or": "Ou",
  "order": "Organizar",
  "originalPrice": "Pagar",
  "otherOptions": "Outras opções",
  "other_plans": "Recomendação diária",
  "pay": "RECARREGAR",
  "pay_now": "Pague agora",
  "pay_with_apple_pay": "Pagar com Apple Pay",
  "pay_with_credit": "Pagar com Cartão de Débito/Crédito",
  "pay_with_google_pay": "Pagar com Google Pay",
  "paymentMethod": "Métodos de pagamento",
  "payment_successful": "Pagamento concluído",
  "platformTip": "Entrar com {x}",
  "previous": "Anterior",
  "price": "Valor",
  "privacyPolicy": "Política de Privacidade",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "Falha na compra",
  "purchaseSucceeded": "Recarregado",
  "read": "Ler",
  "read2": "Ler",
  "readItInTheApp": "Leia no APP",
  "readItNow": "Leia-o agora",
  "readTheBookInLibrary": "Leia os livros da sua Biblioteca",
  "rec": "Rec.",
  "rechargeCoupon": "Preço original",
  "rechargeSave": "Detalhes",
  "refresh": "Atualizar",
  "remove": "Remover",
  "resources": "RECURSOS",
  "saveCoupon": "Oferta{x}",
  "save_my_card_for_future": "Salvar este cartão para compras futuras",
  "save_x": "Desconto {x}",
  "security_code": "Código de segurança",
  "seeMore": "Mais",
  "select_payment_method": "Escolha a forma de pagamento",
  "signID": "Entrar com seu ID de usuário",
  "signIn": "Fazer login",
  "signIntoTapon": "Entrar no TapLivros",
  "signOut": "Sair",
  "signOutCancelledShep": "Sua recarga será cancelada se você sair.",
  "signUpAppleID": "Usuários inscritos com o ID Apple podem fazer login com seu ID de usuário. {value} e verifique seu ID de usuário ao lado da sua foto de perfil na seção \"Eu\".",
  "startIn": "Inicia em:",
  "start_reading_on_tapon": "Comece a ler no TapLivros",
  "switch_account": "Trocar conta",
  "synopsis": "Sinopse",
  "tags": "Tags",
  "termsOfUse": "Termos de Uso",
  "title": "TapLivros -- uma biblioteca de romances on-line na ponta dos seus dedos. A leitura digital já faz parte do nosso modo de vida.  ",
  "toCreate": "Criar",
  "topCount": "count",
  "topUp": "Recarregar",
  "topUpBtn": "RECARREGAR",
  "tryAnother": "Tente outro",
  "unknownError": "Erro desconhecido",
  "unlockNow": "Desbloquear agora",
  "use_new_card": "Use um novo cartão",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "ID de usuário",
  "username": "Nome de usuário",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "Boas-vindas ao TapLivros",
  "x_coins": "{x} Moedas",
  "x_inactive_coupons": "{x} cupons inativos",
  "youIdIncorrect": "O usuário não existe.",
  "youMayAlsoLike": "Você também pode gostar",
  "you_will_get": "Você receberá"
}