<!--  -->
<script lang="ts" setup>
import type { ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { info } = props
</script>

<template>
  <section flex flex-wrap gap-24px>
    <a
      v-for="book in info?.books"
      :key="book.novel_id"
      :href="`/bookDetail?id=${book.novel_id}`"
      w-588px
      flex-none
      cursor-pointer
      flex
      gap-16px
      rounded-lg
      class="group"
    >
      <div w-104px h-139px overflow-hidden rounded-lg flex-none>
        <img img-hover :src="book.avatar" />
      </div>
      <div overflow-hidden w-full>
        <h3 text-18px font-medium line-clamp-1 mt-8px group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div mt-2 line-clamp-3 min-h-60px color="#8C8C8C">
          {{ book?.summary }}
        </div>
        <div w-full flex items-center justify-between mt-8px color="#BFBFBF">
          <div flex items-center>
            <template v-if="book?.author">
              <div flex items-center overflow-hidden>
                <NAvatar
                  w-16px
                  h-16px
                  mr-8px
                  round
                  size="small"
                  objectFit="cover"
                  :src="book?.author_avatar || localImage('avator-icon.png')"
                />
                <span line-clamp-1>{{ book?.author }}</span>
              </div>
              <span class="text-[#E5E5E5] mx-16px">|</span>
            </template>
            <div flex items-center>
              <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
              {{ book?.category?.name }}
            </div>
          </div>
          <div flex items-center>
            <img w-16px h-16px rounded-full mr-8px :src="localImage('see-icon.png')" alt="" />
            <span>{{ book?.display_num }}</span>
          </div>
        </div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
