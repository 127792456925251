{
  "RemoveAllHistory": "Hapus semua riwayat",
  "SignInViewBooks": "Setelah login, pembaca dapat melihat semua buku favoritnya di rak buku",
  "about": "Tentang",
  "accountDontExist": "Pengguna tidak dapat ditemukan",
  "active": "Diskon {x}",
  "add": "Menambahkan",
  "addToLibrary": "Tambahkan ke rak buku",
  "addedToLibrary": "Ditambahkan ke Rak Buku",
  "addedToLibraryBtn": "Tambahkan ke rak buku",
  "appliedOff": "Diskon {x} dipilih",
  "areYou": "Apakah Anda yakin untuk menghapus semua buku yang dipilih ?",
  "areYouRemoveRecords": "Apakah anda yakin ingin menghapus semua riwayat ini? Mohon diperhatikan bahwa anda tidak dapat membatalkan penghapusan ini.",
  "areYouSure": "Apakah Anda yakin untuk keluar?",
  "autoUnlock": "Buka otomatis bab selanjutnya",
  "back": "Kembali",
  "background": "Latar",
  "balance": "Saldo Akun",
  "beginReading": "Cari Buku",
  "betterPlans": "Setelah Masuk, isi ulang akan lebih menguntungkan dibandingkan dengan aplikasi",
  "bonus": "Koin gratis",
  "booksInTotal": "Total {x} buku",
  "cancel": "Batal",
  "cantactUs": "Hubungi kami",
  "card_holder_name": "Nama Pemegang Kartu",
  "card_info": "Informasi Kartu",
  "card_number": "Nomor Kartu",
  "cards": "Koin gratis diskon {x}",
  "chapter_x": "Bab {x}",
  "cheapX": "Up to {x}% ",
  "checkout": "Bayar",
  "chooseYourAccount": "Silahkan pilih akun yang akan diisi ulang",
  "chooseYourPlan": "Pilihan Menu",
  "choose_a_coupon": "Pilih Koin gratis",
  "choose_a_package": "Pilih produk",
  "closeIn": "Berakhir dalam :",
  "coins": "Koin",
  "coinsUnlockStories": "Koin dan Koin gratis dapat digunakan untuk membuka kunci Bab",
  "coinsUnlockThisChapter": " Beli Bab ini dengan {x}  Koin / Koin gratis",
  "coins_added": "Koin telah ditambahkan",
  "coins_free": "{x} Koin + {x} Koin gratis",
  "completed": "Selesai",
  "confirm": "Konfirmasi",
  "confirm_your_account": "Konfirmasi Akun",
  "continue": "Lanjutka",
  "continueReadMore": "Teruskan membaca lebih banyak bab lagi dan temukan lebih banyak cerita seru dan menarik lainnya di applikasi Tapon",
  "continue_topping_up": "Lanjutkan isi ulang",
  "countBooks": "{x} buku",
  "countViews": "{x} dibaca",
  "credit_or_debit_card": "Kartu Kredit atau Debit",
  "details": "Total Koin",
  "discountOffApplied": "Tanpa menggunakan Koin gratis",
  "discountOffInactive": "Tidak ada Koin gratis yang dapat digunakan",
  "done": "Pembersihan berhasil",
  "edit": "Edit",
  "enterBookName": "Masukkan judul buku / author",
  "enterYouIDorEmail": "Masukan ID atau email terdaftar",
  "enter_card_holder_name": "Silakan masukkan nama pemegang kartu",
  "enteryourId": "Silahkan masukkan ID pengguna Anda",
  "expiration_date": "Masa Berlaku",
  "expiredSeeTime": "Item untuk isi ulang sudah kadaluarsa. Silahkan datang lebih awal lagi lain kali.",
  "expiresAtDate": "Kedaluwarsa : {x}",
  "failed": "Gagal masuk",
  "failedToLoad": "Gagal memuat",
  "featuredStories": "Daftar Buku Berkualitas",
  "followUs": "Ikuti kami",
  "fontSize": "Ukuran Huruf",
  "for_all_top_up_packages": "Berlaku untuk semua paket isi ulang",
  "for_x_only": "Hanya berlaku untuk paket {x}",
  "for_x_or_less": "Hanya berlaku untuk paket di bawah {x}",
  "for_x_or_more": "Hanya berlaku untuk paket di atas {x}",
  "free_coins": "Koin gratis",
  "genres": "Kategori",
  "genresTitle": "Kategori",
  "getBouns": "Hadiah {x} Koin gratis",
  "getMore": "Tambahan {x} untuk waktu terbatas",
  "getMoreCoins": "Beli koin untuk membaca",
  "getTheApp": "Unduh Aplikasi",
  "get_free_coins": "Koin Gratis",
  "goToAppTip": "Buka aplikasi",
  "gotoTaponApp": "Buka Applikasi Tapon",
  "history": "Riwayat",
  "home": "Beranda",
  "hot": "Terpopuler",
  "inactiveOff": "Diskon {x} tidak dapat digunakan",
  "including_free_coins": "+ {x} Koin gratis",
  "iosCanAppleId": "Pengguna akun Apple ID dapat menggunakan id pengguna untuk masuk",
  "lastChapter": "Bab sebelumnya",
  "latestUpdate": "Bab Terbaru",
  "legal": "Syarat dan Kebijakan",
  "library": "Rak Buku",
  "limitedTimeOffer": "Penawaran Terbatas : {x}%",
  "limited_time_offer": "hadiah dengan waktu terbatas",
  "limited_time_offers": "Penawaran waktu terbatas",
  "list": "Isi",
  "loggedIn": "Berhasil masuk",
  "loginMoreStory": "Masuk dan telusuri cerita menarik lainnya",
  "loginTip": "Dengan mengklik Login, anda setuju dengan \n {a} dan {b}",
  "more": "Lebih banyak",
  "moreBonusTip": "Disini bisa mendapatkan lebih banyak Koin gratis daripada di App",
  "moreOptions": "Pilihan Menu",
  "moreThanApp": "{x}% jauh lebih banyak dari APP",
  "need_pay": "Perlu dibayar",
  "netWorkError": "Masalah koneksi jaringan",
  "newRelease": "Terbaru",
  "next": "Halaman sebelumnya",
  "nextChapter": "Bab selanjutnya",
  "noActiveCoupons": "Koin gratis",
  "noContent": "Tidak ada konten",
  "noFindServe": "Maaf, layanan ini belum tersedia di negara anda",
  "noResultsFound": "Aduh, maaf buku yang kamu cari gak ketemu nih",
  "notFoundUser": "Pengguna tidak ditemukan, mohon ulangi lagi",
  "notNow": "Nanti saja",
  "notUseAnyCoupons": "Penawaran",
  "okay": "Mengerti",
  "ongoing": "Sedang memuat",
  "or": "Atau",
  "order": "Sortir",
  "originalPrice": "Bayar",
  "otherOptions": "Masuk dengan cara lainnya",
  "other_plans": "Penawaran harian",
  "pay": "Isi saldo",
  "pay_now": "Bayar sekarang",
  "pay_with_apple_pay": "Klik untuk membayar dengan Apple Pay",
  "pay_with_credit": "Bayar dengan kartu kredit / debit",
  "pay_with_google_pay": "Klik untuk membayar dengan Google",
  "paymentMethod": "Cara Pembayaran",
  "payment_successful": "Pembayaran berhasil",
  "platformTip": "Masuk dengan {x}",
  "previous": "Halaman selanjutnya ",
  "price": "Harga",
  "privacyPolicy": "Kebijakan Privasi",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "Isi Ulang gagal",
  "purchaseSucceeded": "Isi Ulang berhasil",
  "read": "Baca",
  "read2": "Mulai baca",
  "readItInTheApp": "Buka App dan bisa dibaca kapan saja",
  "readItNow": "Baca sekarang",
  "readTheBookInLibrary": "Baca buku favoritmu",
  "rec": "Rekomendasi",
  "rechargeCoupon": "Harga asli",
  "rechargeSave": "Rincian",
  "refresh": "Segarkan",
  "remove": "Hapus",
  "resources": "Sumber",
  "saveCoupon": "Penawaran{x}",
  "save_my_card_for_future": "Simpan kartu saya untuk pembayaran selanjutnya",
  "save_x": "Diskon waktu terbatas {x}",
  "security_code": "Kode Keamanan",
  "seeMore": "Lebih banyak",
  "select_payment_method": "Pilih metode pembayaran",
  "signID": "Masuk dengan ID Pengguna",
  "signIn": "Login",
  "signIntoTapon": "Masuk ke Tapon",
  "signOut": "Keluar",
  "signOutCancelledShep": "Setelah keluar dari akun, Anda tidak dapat terus mengisi ulang, apa Anda yakin?",
  "signUpAppleID": "Pengguna yang terdaftar dengan akun Apple bisa masuk dengan ID pengguna, untuk memeriksa ID Pengguna silahkan {value}, di halaman \"Saya\" - di sebelah foto profil Anda",
  "startIn": "Mulai dalam :",
  "start_reading_on_tapon": "Baca di Tapon App",
  "switch_account": "Ganti Akun",
  "synopsis": "Pengantar",
  "tags": "Label",
  "termsOfUse": "Persetujuan Pengguna",
  "title": "Tapon - Dunia fiksimu, menambahkan lebih banyak cerita menarik ke dalam hidupmu.",
  "toCreate": "Area khusus author",
  "topCount": "Top count",
  "topUp": "Pusat Isi Ulang Resmi",
  "topUpBtn": "Isi saldo",
  "tryAnother": "Coba baca buku yang lain yuk~",
  "unknownError": "Eror Tidak Diketahui",
  "unlockNow": "Buka sekarang",
  "use_new_card": "Gunakan kartu lainnya",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "ID Pengguna",
  "username": "Nama Pengguna",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "Selamat Datang di Tapon",
  "x_coins": "{x} Koin",
  "x_inactive_coupons": "{x} lembar Koin gratis tidak dapat digunakan",
  "youIdIncorrect": "Akun tidak terdaftar",
  "youMayAlsoLike": "Anda mungkin juga suka",
  "you_will_get": "Mendapatkan"
}