{
  "RemoveAllHistory": "Supprimer tout l'historique",
  "SignInViewBooks": "Connectez-vous pour afficher tous vos livres préférés dans la bibliothèque",
  "about": "À PROPOS DE NOUS",
  "accountDontExist": "Le compte n'existe pas. Essayez-en un autre. Vous pouvez vous connecter avec votre e-mail ou votre identifiant utilisateur.",
  "active": "{x} OFF",
  "add": "Ajouter à la bibliothèque",
  "addToLibrary": "Ajouter à la bibliothèque",
  "addedToLibrary": "Ajouté à la bibliothèque",
  "addedToLibraryBtn": "Ajouté à la bibliothèque",
  "appliedOff": "{x} OFF Appliqué",
  "areYou": "Êtes-vous sûr(e) de supprimer tous les livres sélectionnés ?",
  "areYouRemoveRecords": "Êtes-vous sûr(e) de vouloir supprimer tous les enregistrements ? Cette action irréversible.",
  "areYouSure": "Êtes-vous certain(e) de vouloir vous déconnecter?",
  "autoUnlock": "Auto-débloquer le chapitre suivant",
  "back": "Retour",
  "background": "Arrière-plan",
  "balance": "Solde",
  "beginReading": "Commencer la lecture",
  "betterPlans": "De meilleurs offres seront disponibles après votre connexion",
  "bonus": "Pièces gratuites",
  "booksInTotal": "{x} livres au total",
  "cancel": "Annuler",
  "cantactUs": "CONTACTEZ-NOUS",
  "card_holder_name": "Nom du titulaire de la carte",
  "card_info": "Informations de carte",
  "card_number": "Numéro de carte",
  "cards": "Coupon de {x} OFF",
  "chapter_x": "Chapitre {x}",
  "cheapX": "Jusqu'à {x}%",
  "checkout": "Payer",
  "chooseYourAccount": "Choisissez votre compte",
  "chooseYourPlan": "Choisissez votre forfait",
  "choose_a_coupon": "Choisissez un coupon",
  "choose_a_package": "Choisissez un article",
  "closeIn": "Fin dans:",
  "coins": "Pièces",
  "coinsUnlockStories": "Les pièces et les pièces gratuites peuvent être utilisées pour débloquer des histoires",
  "coinsUnlockThisChapter": "{x} pièces/pièces gratuites pour débloquer ce chapitre",
  "coins_added": "Les pièces ont été ajoutées à votre compte",
  "coins_free": "{x} pièces + {x} pièces gratuites",
  "completed": "Terminé",
  "confirm": "Confirmer",
  "confirm_your_account": "Confirmez votre compte",
  "continue": "CONTINUER",
  "continueReadMore": "Continuez la lecture pour découvrir d’autres histoires merveilleuses à QuelRoman",
  "continue_topping_up": "Continuer à recharger",
  "countBooks": "{x} Livres",
  "countViews": "{x} Vues",
  "credit_or_debit_card": "Carte de crédit / débit",
  "details": "Pièces",
  "discountOffApplied": "Ne pas utiliser de coupons",
  "discountOffInactive": "Aucun coupon disponible",
  "done": "Fait",
  "edit": "Modifier",
  "enterBookName": "Entrez le nom du livre/l'auteur",
  "enterYouIDorEmail": "Veuillez saisir le bon identifiant ou e-mail",
  "enter_card_holder_name": "Entrez ici le nom du titulaire de la carte",
  "enteryourId": "Entrez votre identifiant ici",
  "expiration_date": "Date d'expiration",
  "expiredSeeTime": "Expiré. A la prochaine fois~",
  "expiresAtDate": "Expirer à {x}",
  "failed": "Échec de la connexion",
  "failedToLoad": "Échec du chargement",
  "featuredStories": "Sélection des Meuilleures Histoires",
  "followUs": "SUIVEZ-NOUS",
  "fontSize": "Taille de police",
  "for_all_top_up_packages": "Pour tous les forfaits de recharge",
  "for_x_only": "Pour {x} uniquement",
  "for_x_or_less": "Pour {x} ou moins",
  "for_x_or_more": "Pour {x} ou plus",
  "free_coins": "Pièces gratuites",
  "genres": "Catégories",
  "genresTitle": "Catégories",
  "getBouns": "Don : {x} pièces gratuites",
  "getMore": "Offre supplémentaire à durée limitée: {x}",
  "getMoreCoins": "Obtenez plus de pièces",
  "getTheApp": "Obtenir l'application",
  "get_free_coins": "Obtenez des pièces gratuites",
  "goToAppTip": "Aller à l'App",
  "gotoTaponApp": "Accédez à l'App QuelRoman",
  "history": "Historique",
  "home": "Accueil",
  "hot": "Le plus populaire",
  "inactiveOff": "{x} OFF - Indisponible",
  "including_free_coins": "+ {x} Perles",
  "iosCanAppleId": "Les utilisateurs IOS peuvent se connecter avec leur identifiant Apple.",
  "lastChapter": "Chapitre précédent",
  "latestUpdate": "Dernier chapitre",
  "legal": "LÉGAL",
  "library": "Bibliothèque",
  "limitedTimeOffer": "Offre à durée limitée : {x} % off",
  "limited_time_offer": "Don à durée limitée",
  "limited_time_offers": "Offre spéciale à durée limitée",
  "list": "Liste",
  "loggedIn": "Connecté",
  "loginMoreStory": "Connectez-vous pour lire toutes les merveilleuses histoires",
  "loginTip": "En vous connectant, vous acceptez les\r\n  {a} et {b}.",
  "more": "Plus",
  "moreBonusTip": "Vous pouvez obtenir plus de pièces gratuites ici que dans l'application",
  "moreOptions": "Plus d'options",
  "moreThanApp": "{x} % de plus que celles de l'application",
  "need_pay": "Prix",
  "netWorkError": "Erreur de connexion réseau",
  "newRelease": "Nouvelle version",
  "next": "Page Suivante",
  "nextChapter": "Chapitre suivant",
  "noActiveCoupons": "Coupon",
  "noContent": "Aucun contenu",
  "noFindServe": "Désolé. Notre service n'est pas encore disponible dans votre pays/région.",
  "noResultsFound": "Oops, aucun resultat n'a été trouvé.",
  "notFoundUser": "Cet utilisateur n'a pas été trouvé. Veuillez entrer à nouveau.",
  "notNow": "Pas maintenant",
  "notUseAnyCoupons": "Réduction",
  "okay": "D'accord",
  "ongoing": "En cours",
  "or": "Ou",
  "order": "Trier par",
  "originalPrice": "Payer",
  "otherOptions": "Autres options",
  "other_plans": "Recommandation",
  "pay": "RECHARGER",
  "pay_now": "Payer maintenant",
  "pay_with_apple_pay": "Payer avec Apple Pay",
  "pay_with_credit": "Payer avec une carte de crédit/débit",
  "pay_with_google_pay": "Payer avec Google Pay",
  "paymentMethod": "Méthodes de paiement",
  "payment_successful": "Paiement réussi",
  "platformTip": "Connectez-vous avec {x}",
  "previous": "Précédent",
  "price": "Prix",
  "privacyPolicy": "Politique de confidentialité",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "Achat échoué",
  "purchaseSucceeded": "Recharge réussie",
  "read": "Lire",
  "read2": "Lire",
  "readItInTheApp": "Lire dans l'APP",
  "readItNow": "Lire maintenant",
  "readTheBookInLibrary": "Lisez les livres de votre bibliothèque",
  "rec": "Recommander",
  "rechargeCoupon": "Prix ​​d'origine",
  "rechargeSave": "Détails",
  "refresh": "Rafraîchir",
  "remove": "Enlever",
  "resources": "RESSOURCES",
  "saveCoupon": "Réduire {x}",
  "save_my_card_for_future": "Sauvegarder ma carte pour de futurs paiements",
  "save_x": "Réduire {x}",
  "security_code": "Code de sécurité",
  "seeMore": "Plus",
  "select_payment_method": "Sélectionnez un mode de paiement",
  "signID": "Connectez-vous avec votre identifiant utilisateur",
  "signIn": "Se connecter",
  "signIntoTapon": "Connectez-vous à QuelRoman",
  "signOut": "Se déconnecter",
  "signOutCancelledShep": "Votre recharge sera annulée si vous vous déconnectez.",
  "signUpAppleID": "Les utilisateurs inscrits avec un ID Apple peuvent se connecter avec un identifiant utilisateur. {value} et vérifiez votre identifiant d'utilisateur à côté de votre image dans la section Profil.",
  "startIn": "Début dans :",
  "start_reading_on_tapon": "Commencez à lire sur QuelRoman",
  "switch_account": "Changer de compte",
  "synopsis": "Synopsis",
  "tags": "Tags",
  "termsOfUse": "Conditions d'utilisation",
  "title": "QuelRoman - une bibliothèque d'histoires en ligne à portée de main. La lecture numérique est déjà un mode de vie.",
  "toCreate": "Créer",
  "topCount": "count",
  "topUp": "Centre de recharge",
  "topUpBtn": "RECHARGER",
  "tryAnother": "Essayez un autre livre",
  "unknownError": "Erreur inconnue",
  "unlockNow": "Débloquez maintenant",
  "use_new_card": "Utiliser une nouvelle carte",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "ID d'utilisateur",
  "username": "Nom d'utilisateur",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "Bienvenue à QuelRoman",
  "x_coins": "{x} pièces",
  "x_inactive_coupons": "{x} coupons indisponibles",
  "youIdIncorrect": "L'utilisateur n'existe pas",
  "youMayAlsoLike": "Vous pourriez aussi aimer",
  "you_will_get": "Vous aurez"
}