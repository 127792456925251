<script setup lang="ts">
import Cookies from 'js-cookie'

const props = defineProps<{
  alertImg?: string
}>()
const router = useRouter()
const route = useRoute()

const targetPath = ref('/c')
const whiteRouter = ['/sso', '/login']
const showModal = ref(false)

const hideModal = () => {
  showModal.value = false
}
const jumpFun = () => {
  router.push({ path: targetPath.value })
  hideModal()
  zaq('taponweb-homepopup-click')
}
// 初始
const init = () => {
  if (
    targetPath.value !== route.path &&
    !whiteRouter.includes(route.path) &&
    !route.path.includes('/bookDetail/') &&
    !Cookies.get('showAlertModal') &&
    props.alertImg
  ) {
    showModal.value = true
    Cookies.set('showAlertModal', 'true', { expires: 1 })
    zaq('taponweb-homepopup-view')
  }
}

watch(route, val => {
  init()
})
</script>

<template>
  <div>
    <NModal v-model:show="showModal" :close-on-esc="false" :mask-closable="false">
      <div>
        <div class="w-375px h-500px b-rd-12px overflow-hidden cursor-pointer" @click="jumpFun()">
          <img :src="props.alertImg" w-full h-full />
        </div>
        <div mt-20px m-auto text-28px cursor-pointer bg-white i-carbon:close-filled @click="hideModal()" />
      </div>
    </NModal>
  </div>
</template>

<style lang="less" scoped>
.n-modal {
  box-shadow: none !important;
}
</style>
