// 倒计时，返回天时分秒
export function useCountdownFn() {
  const days = ref(0)
  const hours = ref(0)
  const hours2 = ref(0)
  const minutes = ref('00')
  const seconds = ref('00')

  // 小于10补0
  const addZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`
  }

  const currentTime = ref(0)

  // 倒计时
  const countDown = (time: number, onEnd?: () => void) => {
    const timer = setInterval(() => {
      if (time > 0) {
        time--
        days.value = Math.floor(time / 60 / 60 / 24)
        hours.value = Math.floor((time / 60 / 60) % 24)
        hours2.value = Math.floor(time / 60 / 60)
        minutes.value = addZero(Math.floor((time / 60) % 60))
        seconds.value = addZero(Math.floor(time % 60))
      } else {
        clearInterval(timer)
        onEnd && onEnd()
      }
      currentTime.value = time
    }, 1000)
  }

  const currentHours = computed(() => {
    return days.value >= 4 ? addZero(hours.value) : addZero(hours2.value)
  })

  return {
    days,
    hours: currentHours,
    minutes,
    seconds,
    currentTime,
    countDown,
  }
}
