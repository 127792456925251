import type { IConfig } from '@/composables/request'

// 获取用户信息
export function asyncGetUserInfo() {
  return request({
    url: '/tapon/user',
    method: 'get',
  })
}

// 获取用户余额
export function asyncGetUserBalance() {
  return request({
    url: '/tapon/user/wallet',
    method: 'get',
    noLoad: true,
  } as IConfig)
}

// 根据邮箱查询用户id
export function asyncEmailSearchMember(params: { q: string }) {
  return request({
    url: '/web/member_search',
    method: 'get',
    params,
    noMessage: true,
  } as IConfig)
}

// 根据用户id查询用户信息
export function asyncGetUserInfoById(data: { member_id: string | number }) {
  return request({
    url: '/web/member_check',
    method: 'post',
    data,
    noMessage: true,
  } as IConfig)
}

// 获取服务器当前时间
export function asyncGetServerTime() {
  return request({
    url: '/tapon/settings/iso_time',
    method: 'get',
    noMessage: true,
  } as IConfig)
}
