import NProgress from 'nprogress'
import { router } from './router'
import { asyncGetUserInfo } from '@/api/user'

export const install = () => {
  const userStore = useUserStore()
  router.beforeEach(async (to, from, next) => {
    if (to.path !== from.path) {
      NProgress.start()
    }
    if (!['/sso'].includes(to.path) && to.query.token) {
      userStore.setUserData('token', to.query.token)
      const { data } = await asyncGetUserInfo()
      userStore.setUserData('userInfo', data)
      const query = {
        ...to.query,
      }
      delete query.token
      delete query.path
      next({
        path: to.path,
        query,
      })
    } else {
      next()
    }
  })
  router.afterEach(() => {
    NProgress.done()
  })
}
