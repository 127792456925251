<!--  -->
<script lang="ts" setup>
import type { ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { t } = useI18n()
const { info } = props
</script>

<template>
  <section flex flex-wrap gap-24px>
    <a
      v-for="book in info?.books?.slice(0, 6)"
      :key="book.novel_id"
      :href="`bookDetail?id=${book.novel_id}`"
      w-384px
      p-8px
      flex-none
      cursor-pointer
      flex
      gap-8px
      rounded-lg
      class="bg-[rgba(245,245,245,0.5)] group"
    >
      <div w-92px h-122px overflow-hidden rounded-lg flex-none>
        <img img-hover :src="book.avatar" />
      </div>
      <div overflow-hidden>
        <h3 text-18px font-medium line-clamp-2 h-50px mt-2 group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div my-8px>
          <span :class="book?.serial_status === 1 ? 'text-[#FFB133]' : 'color-primary'">
            {{ book?.serial_status_text }}
          </span>
          <span class="text-[#E5E5E5] mx-8px">|</span>
          <span color="#737373">{{ t('chapter_x', { x: book?.updated_chapters }) }}</span>
        </div>
        <div mt-2 color="#8C8C8C">{{ book?.category?.name }}</div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
