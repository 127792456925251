<!--  -->
<script lang="ts" setup>
import type { ILayoutItems, ITag } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { info } = props
const activeTag = ref<ITag>({
  id: 0,
  name: '',
})

onMounted(() => {
  // 第一个标签的书
  const tags = info?.tags as ITag[]
  if (tags.length > 0) {
    activeTag.value = tags[0]
  }
})
</script>

<template>
  <section flex flex-wrap gap-16px>
    <div
      v-for="tag in info.tags"
      :key="tag.id"
      py-8px
      px-16px
      flex-none
      cursor-pointer
      text-16px
      rounded-full
      select-none
      hover:color-primary
      class="bg-[#F5F5F5] text-[#595959]"
      :class="{
        'bg-[rgba(249,37,0,0.0500)] text-primary': tag.id === activeTag.id,
      }"
      @click="activeTag = tag"
    >
      {{ tag.name }}
    </div>
  </section>
  <section mt-24px flex gap-25px>
    <a
      v-for="book in activeTag.books"
      :key="book.novel_id"
      :href="`/bookDetail?id=${book.novel_id}`"
      w-150px
      flex-none
      cursor-pointer
      class="group"
    >
      <div w-full h-200px overflow-hidden rounded-lg>
        <img img-hover :src="book.avatar" />
      </div>
      <div mt-16px overflow-hidden>
        <h3 text-18px font-medium line-clamp-2 min-h-50px group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div mt-2 color="#8C8C8C">{{ book?.category?.name }}</div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
