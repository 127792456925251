<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems | { books: IBook[] }
}>()
const { t } = useI18n()
const localLang = ref(process.env.LANG || 'en')
</script>

<template>
  <section flex gap-25px text-14px>
    <div
      v-for="book in props?.info?.books"
      :key="book.novel_id"
      w-384px
      p-24px
      rounded-lg
      flex-none
      class="bg-[rgba(245,245,245,0.5)] group"
    >
      <h3 text-24px font-medium line-clamp-1 group-hover:text-primary>
        {{ book.title }}
      </h3>
      <div my-16px overflow-hidden color="#8c8c8c">
        <div flex items-center gap-24px>
          <div v-if="book?.author" flex items-center overflow-hidden>
            <NAvatar
              w-16px
              h-16px
              mr-8px
              round
              size="small"
              objectFit="cover"
              :src="book?.author_avatar || localImage('avator-icon.png')"
            />
            <span line-clamp-1>{{ book?.author }}</span>
          </div>
          <div flex items-center>
            <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
            {{ book?.category?.name }}
          </div>
        </div>
      </div>
      <div mb-24px line-clamp-3 min-h-60px color="#595959">
        {{ book?.summary }}
      </div>
      <div flex items-end justify-between>
        <div relative top-0 left-0 w-129px h-172px>
          <div absolute top-0 bottom-0 my-auto right--20px w-50px h-140px rounded-lg bg="#F3F3F3" />
          <div absolute top-0 bottom-0 my-auto right--10px w-50px h-156px rounded-lg bg="#EBEBEB" />
          <div absolute w-full h-full overflow-hidden rounded-lg>
            <img img-hover :src="book.avatar" />
          </div>
        </div>
        <a :href="`/bookDetail?id=${book.novel_id}`" text-primary flex items-center>
          {{ t('read') }}
          <iconpark-icon
            width="18"
            height="18"
            ml-2
            :color="localLang === 'ar' ? '#fd6b0a' : '#DE0008'"
            :name="localLang === 'ar' ? 'left' : 'right'"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="less" scoped></style>
