export const lang = process.env.LANG || 'en'

// 是否是中文
export const isZh = ['hans', 'hant'].includes(lang)

// 用户协议
export const USER_AGREEMENT = `https://support.tapon.com/user_agreement_${isZh ? `zh_${lang}` : lang}.html`

// 隐私政策
export const PRIVACY_POLICY = `https://support.tapon.com/privacy_policy_${isZh ? `zh_${lang}` : lang}.html`

// 深度链接
export const DEEP_LINK = 'https://link.tapon.com/9LZM/nrevusb8'

// 苹果商店链接
export const APP_STORE_LINK = ' https://apps.apple.com/app/id1615344447'

// 谷歌商店链接
export const GOOGLE_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.tapon.read'

export const COLORS = {
  primary: lang === 'ar' ? '#fd6b0a' : '#f92500',
  hover: lang === 'ar' ? '#fd6b0a' : '#fa5134',
}
