<script setup lang="ts">
import { ref } from 'vue'

import Alert from './alert.vue'
import Bulb from './bulb.vue'
import { asyncGetBanners } from '@/api/home'
import type { IBanner } from '@/types/home'

const alertImg = ref()

// 小灯泡
const bulbImg = ref()
const getBanners = async (type: number) => {
  const { data } = await asyncGetBanners({ type })
  if (type === 4) {
    if (data && data.length > 0) {
      const banner = data.filter((v: IBanner) => v.platforms === 'pc')
      if (banner && banner.length > 0) {
        bulbImg.value = banner[0].image
      }
    }
  } else if (type === 3) {
    if (data && data.length > 0) {
      const banner = data.filter((v: IBanner) => v.platforms === 'pc')
      if (banner && banner.length > 0) {
        alertImg.value = banner[0].image
      }
    }
  }
}

onMounted(async () => {
  await getBanners(3)
  await getBanners(4)
})
</script>

<template>
  <div>
    <!-- 弹窗组件 -->
    <Alert v-if="alertImg" :alertImg="alertImg" />
    <!-- 小灯泡组件 -->
    <Bulb v-if="bulbImg" :bulbImg="bulbImg" />
  </div>
</template>
