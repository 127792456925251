{
  "RemoveAllHistory": "Borrar todos los datos",
  "SignInViewBooks": "Después de iniciar sesión, puede ver todos tus colecciones",
  "about": "Acerca",
  "accountDontExist": "No se encuentra el usuario, intenta iniciar la sesión con otro email o ID",
  "active": "{x} OFF",
  "add": "Añadir",
  "addToLibrary": "Añadir en la biblioteca",
  "addedToLibrary": "Agregado a la biblioteca",
  "addedToLibraryBtn": "Ha añadido en la biblioteca",
  "appliedOff": "Elegido {x} OFF",
  "areYou": "¿Quieres eliminar los libros seleccionados?",
  "areYouRemoveRecords": "¿Seguro que quieres borrar todo tu historial? No puede deshacerlo",
  "areYouSure": "¿Quieres cerrar la sesión?",
  "autoUnlock": "Desbloquear el siguiente capítulo automáticamente",
  "back": "Volver",
  "background": "Fondo",
  "balance": "Saldo de cuenta",
  "beginReading": " Explorar libros",
  "betterPlans": "Disfruta una recarga más económica al iniciar la sesión",
  "bonus": "Moneda gratis",
  "booksInTotal": "Total {x} libros",
  "cancel": "Cancelar",
  "cantactUs": "Contáctanos",
  "card_holder_name": "Titular de tarjeta",
  "card_info": "Información bancaria",
  "card_number": "Número de tarjeta",
  "cards": "Cupón de {x} OFF",
  "chapter_x": "Capítulo {x}",
  "cheapX": "Hasta el {x}% gratis",
  "checkout": "Pagar",
  "chooseYourAccount": "Elige la cuenta que quieres recargar",
  "chooseYourPlan": "Elige un menú",
  "choose_a_coupon": "Elegir cupón",
  "choose_a_package": "Elegir producto",
  "closeIn": "Finaliza en:",
  "coins": "Monedas",
  "coinsUnlockStories": "Desbloquea capítulos con monedas y Moneda gratis",
  "coinsUnlockThisChapter": "Este capítulo cuesta {x}  monedas/Moneda gratis",
  "coins_added": "moneda(s) han llegado a tu cuenta",
  "coins_free": "{x} moneda(s)+{x} moneda(s) gratis",
  "completed": "Concluido",
  "confirm": "Confirmar",
  "confirm_your_account": "Confirma de cuenta",
  "continue": "CONTINUAR",
  "continueReadMore": "Sigue leyendo más capítulos para descubrir más historias maravillas en Tesonovela",
  "continue_topping_up": "Recargar más",
  "countBooks": "{x} Ese libro",
  "countViews": "{x} ha leído",
  "credit_or_debit_card": "Tarjeta de crédito o débito",
  "details": "Monedas",
  "discountOffApplied": "Pagar sin cupón",
  "discountOffInactive": "No tienes cupón",
  "done": "Eliminado",
  "edit": "Editar ",
  "enterBookName": "Ingresa título/autor",
  "enterYouIDorEmail": " Ingrese el ID o correo para la APP",
  "enter_card_holder_name": "Ingresar el titular de tarjeta",
  "enteryourId": "Ingresa su ID para iniciar la sesión",
  "expiration_date": "Fecha de venecimiento",
  "expiredSeeTime": "El producto recargado ha caducado, ven más temprano ~",
  "expiresAtDate": "Vence en {x}",
  "failed": "Inicio de sesión fallido",
  "failedToLoad": "Error al cargar.",
  "featuredStories": "Los mejores libros",
  "followUs": "Síguenos",
  "fontSize": "Tamaño de letra",
  "for_all_top_up_packages": "Para todos los planes de recarga",
  "for_x_only": "Disponible solo para {x}",
  "for_x_or_less": "Disponible para {x} o menos",
  "for_x_or_more": "Disponible para {x} o más",
  "free_coins": "Moneda gratis",
  "genres": "Categorías",
  "genresTitle": "Categorías",
  "getBouns": "Regalar {x} Moneda gratis",
  "getMore": "Más oferta por tiempo limitado: {x}",
  "getMoreCoins": "Obtenga más monedas",
  "getTheApp": "Descargar la app",
  "get_free_coins": "Moneda gratis",
  "goToAppTip": "ir a la aplicación",
  "gotoTaponApp": "A la App Tesonovela",
  "history": "Historia",
  "home": "Inicio",
  "hot": "Novedades",
  "inactiveOff": "{x} OFF no está disponible",
  "including_free_coins": "+ {x} Moneda Gratis",
  "iosCanAppleId": "Usuarios de IOS puede ingresar con tu Apple ID.",
  "lastChapter": "Capítulo anterior",
  "latestUpdate": "El último capítulo",
  "legal": "Condiciones legales",
  "library": "Biblioteca",
  "limitedTimeOffer": "Oferta {x}% en tiempo limitado",
  "limited_time_offer": "Regalar tiempo limitado",
  "limited_time_offers": "Oferta especial tiempo limitado",
  "list": "Índice",
  "loggedIn": "Sesión iniciada",
  "loginMoreStory": "Iniciar sesión para descubrir más historias maravillas",
  "loginTip": "Al iniciar sesión, significa que ha aceptado \n {a} y {b}.",
  "more": "Más",
  "moreBonusTip": "Obtener más Moneda gratis que la App aquí",
  "moreOptions": "Mostrar opciones ",
  "moreThanApp": "{x}% más que la aplicación",
  "need_pay": "Pago en total",
  "netWorkError": "Error de conexión de red",
  "newRelease": "Más popular",
  "next": "Siguiente",
  "nextChapter": "Siguiente capítulo",
  "noActiveCoupons": "Cupón",
  "noContent": "No hay contenido",
  "noFindServe": "Lo sentimos, nuestro servicio aún no está disponible para su país",
  "noResultsFound": "No se ha encontrado el libro",
  "notFoundUser": "No ha encontrado el usuario, ingrésalo de nuevo ",
  "notNow": "Decidir más tarde",
  "notUseAnyCoupons": "Descuento",
  "okay": "Ya lo sé",
  "ongoing": "En curso",
  "or": "o",
  "order": "Orden",
  "originalPrice": "Pago",
  "otherOptions": "Iniciar sesión con otros",
  "other_plans": "Recomendación diaria",
  "pay": "Recargar",
  "pay_now": "Pagar ahora",
  "pay_with_apple_pay": "Haz clic para usar Apple Pay",
  "pay_with_credit": "Pagar con tarjeta de crédito o débito",
  "pay_with_google_pay": "Haz clic para usar Google Pay",
  "paymentMethod": "Forma de pago",
  "payment_successful": "Pagar con éxito",
  "platformTip": "Iniciar sesión con {x}",
  "previous": "Anterior",
  "price": "Precio",
  "privacyPolicy": "Políctica de Privacidad",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "No se puede recargar",
  "purchaseSucceeded": "Recarga con éxito",
  "read": "Leer",
  "read2": "Leer",
  "readItInTheApp": "Ir a APP para leer cuando quieras",
  "readItNow": "Leer ahora",
  "readTheBookInLibrary": "Leer tus colecciones",
  "rec": "Recom.",
  "rechargeCoupon": "Precio original",
  "rechargeSave": "Detalle",
  "refresh": "Vuelve a intentarlo",
  "remove": "Eliminar",
  "resources": "Recurso",
  "saveCoupon": "Descuento de{x}",
  "save_my_card_for_future": "Guardar mi tarjeta para futuras compras",
  "save_x": "Descuento temporal de {x}",
  "security_code": "Código de seguridad",
  "seeMore": "Más",
  "select_payment_method": "Elegir el método de pago",
  "signID": "Iniciar sesión con ID",
  "signIn": "Iniciar sesión",
  "signIntoTapon": "Iniciar sesión en Tesonovela",
  "signOut": "Cerrar sesión",
  "signOutCancelledShep": "¿Quieres cerrar la sesión? No puedes recargar después de cerrar la sesión. ",
  "signUpAppleID": "Los usuarios registrados con cuenta de Apple pueden iniciar sesión con su ID de usuario Puedes {value} y encontrar su ID de usuario al lado de \"Mi\" - Imagen",
  "startIn": "Empieza en:",
  "start_reading_on_tapon": "Leer en Tapon APP",
  "switch_account": "Cambiar cuenta",
  "synopsis": "Sinopsis",
  "tags": "Etiqueta",
  "termsOfUse": "Acuerdo de Usuario",
  "title": "Tesonovela - un mundillo con miles de novelas a tu alcance. La lectura digital ya es una forma de vida.",
  "toCreate": "Zona de autores",
  "topCount": "count",
  "topUp": "Centro de recarga oficial",
  "topUpBtn": "Recargar",
  "tryAnother": "¿Busca otro?",
  "unknownError": "Error desconocido",
  "unlockNow": "Desbloquear ahora",
  "use_new_card": "Usar otra tarjeta",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "ID de usuario",
  "username": "Nombre de usuario",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "Te damos la bienvenida a Tesonovela",
  "x_coins": "{x} moneda(s)",
  "x_inactive_coupons": "{x} cupon(es) indisponible(s)",
  "youIdIncorrect": "Usuario no existe",
  "youMayAlsoLike": "También te puede gustar",
  "you_will_get": "Obtener"
}