<!--  -->
<script lang="ts" setup>
import type { DropdownOption, InputInst } from 'naive-ui'
import type { VNodeChild } from 'vue'
import { asyncGetUserBalance } from '@/api/user'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n()

const userStore = useUserStore()
const appStore = useAppStore()

const tabValue = ref(route.path || '/')
const inputInstRef = ref<InputInst | null>(null)
const searchValue = ref('')
const isSearch = ref(false)

const showLibraryPopover = ref(false) // 显示书架弹窗

const imgSrc = ref(localImage(`logo/logo-${process.env.LANG || 'en'}.png`))

const tabs = [
  {
    value: '/',
    label: 'home',
  },
  {
    value: '/classification',
    label: 'genres',
  },
  {
    value: 'writer',
    label: 'toCreate',
  },
  {
    value: '/bookshelf',
    label: 'library',
  },
  {
    value: '/c',
    label: 'topUpBtn',
  },
]

const tabChange = (val: string) => {
  if (val === '/bookshelf' && !userStore.token) {
    showLibraryPopover.value = true
    return false
  }
  if (val === 'writer') {
    window.open('https://www.hopwriter.com/')
    zaq('home-writer-click')
    return false
  }
  if (val === '/c') {
    zaq('taponweb-homerechargetab-click')
  }

  router.push(val)
}

watch(
  route,
  val => {
    tabValue.value = val.path || '/'
    if (route.path.includes('/search')) {
      searchValue.value = `${route.query?.q}`
      isSearch.value = true
    }
  },
  { immediate: true },
)

const language = ref(appStore.language || 'en')

const options = [
  {
    label: 'English',
    key: 'en',
  },
  {
    label: 'Español',
    key: 'es',
  },
  {
    label: '한국어',
    key: 'ko',
  },
  {
    label: 'Bahasa Indonesia',
    key: 'id',
  },
  {
    label: 'Português',
    key: 'pt',
  },
  {
    label: 'ภาษาไทย',
    key: 'th',
  },
  {
    label: '中文（简体）',
    key: 'hans',
  },
  {
    label: '中文（繁体）',
    key: 'hant',
  },
  {
    label: 'اللغة العربية',
    key: 'ar',
  },
  {
    label: 'Français',
    key: 'fr',
  },
  {
    label: 'Deutsch',
    key: 'de',
  },
  {
    label: '日本語',
    key: 'ja',
  },
]

// 语言渲染项
const renderDropdownLabel = (option: DropdownOption) => {
  if (option.type === 'group') {
    return option.label as VNodeChild
  }
  return h(
    'a',
    {
      href: 'javascript:void(0)',
      style: {
        width: '120px',
        display: 'block',
        textAlign: 'center',
      },
    },
    {
      default: () => option.label as VNodeChild,
    },
  )
}

// 选择语言
const handleSelect = (key: string) => {
  language.value = key
  locale.value = language.value
  appStore.setAppCookieData('language', key)
  const isZh = ['hant', 'hans'].includes(key)

  if (process.env.VITE_MODE === 'production') {
    const url = `https://${key === 'en' ? 'www' : isZh ? `zh-${key}` : key}.tapon.com/`
    window.location.href = url
  } else if (['test', 'development'].includes(process.env.VITE_MODE)) {
    const lang = isZh ? `-zh-${key}` : key === 'en' ? '' : `-${key}`
    const url = `https://tapon-pc${lang}-test.joyread.cc/`
    window.location.href = url
  }
}

// 用户余额
const wallet = ref({
  wealth: 0, // 书币
  wealth_coupon: 0, // 书券
})
const isLoading = ref(false)

// 获取用户余额
const getUserBalance = async () => {
  isLoading.value = true
  const { data } = await asyncGetUserBalance()
  wallet.value = data
  isLoading.value = false
}

// 点击搜索按钮
const handleSearch = () => {
  // router.push(`/search?q=${searchValue.value}`)
  if (inputInstRef.value) {
    isSearch.value = true
    inputInstRef.value?.focus()
  }
}
// 回车搜索
const handleEnter = () => {
  if (route.path.includes('/search')) {
    router.replace(`/search?q=${searchValue.value}`)
  } else {
    router.push(`/search?q=${searchValue.value}`)
  }
}
// 关闭搜索
const handCloseSearch = () => {
  isSearch.value = false
  // searchValue.value = ''
  // if (route.path.includes('/search')) {
  //   router.back()
  // }
}

// 图片加载失败
const handleError = (e: any) => {
  e.target.src = localImage('avator-icon.svg')
}

// 背景颜色
const bgColor = computed(() => {
  if (route.path.includes('/bookDetail/')) {
    return appStore.readTheme.primary
  }
  return '#fff'
})

// 字体颜色
const textColor = computed(() => {
  if (route.path.includes('/bookDetail/') && appStore.readTheme.id === '4') {
    return '#D9D9D9'
  }
  return '#262626'
})
</script>

<template>
  <section
    fixed
    top-0
    left-0
    w-100vw
    h-72px
    z-99
    :style="{
      background: bgColor,
      color: textColor,
    }"
  >
    <div web-container h-70px flex items-center justify-between>
      <div flex items-center>
        <img
          mr-60px
          h-40px
          cursor-pointer
          :src="imgSrc"
          alt="logo"
          @error="imgSrc = localImage(`logo/logo-en.png`)"
          @click="router.push('/')"
        />
        <div v-if="!isSearch" flex-none flex items-center gap-x-50px>
          <div
            v-for="item in tabs"
            :key="item.value"
            text-16px
            py-8px
            border-b-2px
            cursor-pointer
            border-transparent
            transition
            hover="border-[#BFBFBF]"
            :class="{ '!border-primary font-medium': tabValue === item.value }"
            @click="tabChange(item.value)"
          >
            <div>
              <NPopover
                v-if="!userStore.token && item.value === '/bookshelf'"
                trigger="manual"
                placement="bottom"
                displayDirective="show"
                :show="showLibraryPopover"
                raw
                rounded-lg
                :onClickoutside="() => (showLibraryPopover = false)"
                arrow-wrapper-class="addPopverClass"
              >
                <template #trigger>
                  <div>{{ t('library') }}</div>
                </template>
                <div p-4 bg-white rounded-lg>
                  <h3 color="#262626" text-18px>
                    {{ t('readTheBookInLibrary') }}
                  </h3>
                  <p color="#8C8C8C" text-14px mt-2 mb-6>
                    {{ t('SignInViewBooks') }}
                  </p>
                  <div flex justify-end items-center select-none>
                    <div cursor-pointer color="#BFBFBF" @click="showLibraryPopover = false">
                      {{ t('notNow') }}
                    </div>
                    <NButton class="py-5px px-20px ml-6 btn" @click="userStore.setShowLogin(true)">
                      {{ t('signIn') }}
                    </NButton>
                  </div>
                </div>
              </NPopover>
              <div v-else-if="item.value === '/c'" class="relative">
                <span class="badge-pay">{{ t('get_free_coins') }}</span>
                {{ t(item.label) }}
              </div>
              <div v-else>{{ t(item.label) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div flex-none flex items-center text-lg>
        <div flex items-center>
          <iconpark-icon width="18" height="18" name="sousuo" :color="textColor" cursor-pointer @click="handleSearch" />
          <NInput
            ref="inputInstRef"
            v-model:value="searchValue"
            class="ml-2 search-input transition-all duration-300"
            :class="isSearch ? '!w-800px !opacity-100' : '!w-0 !opacity-0'"
            type="text"
            :placeholder="t('enterBookName')"
            @keyup.enter="handleEnter"
          />
          <div v-if="isSearch" ml-2 i-carbon-close cursor-pointer @click="handCloseSearch" />
        </div>
        <template v-if="!isSearch">
          <NDropdown
            trigger="hover"
            :options="options"
            inverted
            :showArrow="true"
            :value="language"
            :renderLabel="renderDropdownLabel"
            @select="handleSelect"
          >
            <div flex items-center mx-10 text-16px cursor-pointer>
              <iconpark-icon mr-2 width="20" height="20" name="yuyan" :color="textColor" />
              <div flex-none flex items-center hover:text-primary>
                {{ options.find(v => v.key === language)?.label || 'English' }}
                <div ml-1 i-carbon-chevron-down />
              </div>
            </div>
          </NDropdown>
          <NButton
            v-if="!userStore.token"
            type="primary"
            class="btn px-20px h-32px text-16px"
            @click="userStore.setShowLogin(true)"
          >
            {{ t('signIn') }}
          </NButton>
          <NPopover
            v-else
            trigger="click"
            arrow-wrapper-class="addPopverClass"
            placement="bottom-start"
            displayDirective="show"
          >
            <template #trigger>
              <div
                flex-none
                ml-2
                rounded-full
                cursor-pointer
                border="3px solid transparent"
                hover:border="3px solid #F92500"
                hover:border-opacity-10
                @click="getUserBalance"
              >
                <img w-32px h-32px rounded-full :src="userStore.userInfo?.avatar" alt="" @error="handleError" />
              </div>
            </template>
            <div min-w-224px py-2>
              <div flex items-center>
                <img ml-2 w-48px h-48px rounded-full :src="userStore.userInfo?.avatar" alt="" @error="handleError" />
                <div ml-4>
                  <div font-semibold text-2xl>
                    {{ userStore.userInfo?.name }}
                  </div>
                  <p color="#BFBFBF">ID: {{ userStore.userInfo?.id }}</p>
                </div>
              </div>
              <NDivider class="!my-15px" />
              <template v-if="!isLoading">
                <div ml-2 flex items-center w-full overflow-hidden>
                  <img w-32px h-32px :src="localImage('wealth-icon.png')" alt="" />
                  <div ml-4>
                    <div font-semibold text-lg>{{ wallet.wealth }}</div>
                    <p color="#BFBFBF">{{ t('coins') }}</p>
                  </div>
                </div>
                <div ml-2 mt-3 flex items-center w-full overflow-hidden>
                  <img w-32px h-32px :src="localImage('wealth_coupon-icon.png')" alt="" />
                  <div ml-4>
                    <div font-semibold text-lg>{{ wallet.wealth_coupon }}</div>
                    <p color="#BFBFBF">{{ t('bonus') }}</p>
                  </div>
                </div>
              </template>
              <NSpin v-else py-4 mx-auto block w-max-content size="medium" />

              <NButton
                tag="a"
                href="/c"
                type="primary"
                block
                h-36px
                mt-4
                mb-3
                class="btn"
                @click="zaq('personalcenter-topup-click')"
              >
                {{ t('topUpBtn') }}
              </NButton>
              <button w-full h-36px bg-transparent text-primary @click="userStore.logout()">
                {{ t('signOut') }}
              </button>
            </div>
          </NPopover>
        </template>
      </div>

      <Login />
    </div>
  </section>
</template>

<style lang="less" scoped>
:deep(.n-input) {
  --n-border: none !important;
  --n-border-disabled: none !important;
  --n-border-hover: none !important;
  --n-border-focus: none !important;
  --n-border-focus-error: none !important;
  --n-box-shadow-focus: none !important;
}
.badge-pay {
  position: absolute;
  top: -18px;
  left: 85%;
  height: 22px;
  color: #fff;
  padding: 2px 4px 2px 4px;
  border-radius: 8px 8px 8px 0px;
  background: linear-gradient(90deg, rgba(249, 37, 0, 0.92) 0%, rgba(252, 85, 56, 0.961209) 51.51%, #ff826c 100%);
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

[dir='rtl'] .badge-pay {
  background: linear-gradient(103deg, #f99451 0%, #fd6b0a 100%);
}
</style>
