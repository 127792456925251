import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'

import './styles/reset.css'
import 'uno.css'
import './styles/main.less'
import pinia from './store' // 确保路径正确

const app = createApp(App)
app.use(pinia) // 在所有其它插件之前安装 pinia

const lang = process.env.LANG || 'en'
if (lang === 'ar') {
  document.documentElement.setAttribute('dir', 'rtl')
  document.documentElement.setAttribute('class', 'direction-rtl')
} else {
  document.documentElement.setAttribute('dir', 'ltr')
}
if (['production'].includes(process.env.VITE_MODE!)) {
  Sentry.init({
    app,
    environment: process.env.VITE_MODE,
    release: process.env.SENTRY_VERSION || '0.0.1',
    dsn: 'https://425ce3ee11764bc88fffbbeaf18da421@o4504093839917056.ingest.sentry.io/4504201448521728',
    tracesSampleRate: 1.0,
  })
}

// 注入插件
Object.values(import.meta.globEager('./modules/*.ts')).forEach(i => i.install?.(app))

app.mount('#app')
