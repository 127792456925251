<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { t } = useI18n()

const { info } = props

const activeBook = ref<IBook>({
  id: 0,
})

onMounted(() => {
  const INFO = info.books as IBook[]
  if (INFO.length > 0) {
    activeBook.value = INFO[0]
  }
})
</script>

<template>
  <section mt-32px class="bg-[rgba(245,245,245,0.5000)]">
    <div web-container pb-56px pt-32px>
      <Title :name="info.name" :info="info" />
      <div flex items-center gap-16px>
        <img
          v-for="book in info?.books"
          :key="book.novel_id"
          :src="book.avatar"
          w-77px
          h-102px
          flex-none
          cursor-pointer
          rounded-lg
          :class="{
            'border-3 border-[#FF8873]': activeBook.novel_id === book.novel_id,
          }"
          @click="activeBook = book"
        />
      </div>
      <div mt-24px w-full flex-none cursor-pointer flex gap-34px rounded-lg>
        <div w-196px h-260px overflow-hidden rounded-lg flex-none>
          <img img-hover :src="activeBook.avatar" />
        </div>
        <div w-full>
          <h3 text-24px font-semibold line-clamp-1 mt-1>
            {{ activeBook.title }}
          </h3>
          <div w-full flex items-center my-16px color="#8C8C8C">
            <template v-if="activeBook?.author">
              <div flex items-center overflow-hidden>
                <NAvatar
                  w-16px
                  h-16px
                  mr-8px
                  round
                  size="small"
                  objectFit="cover"
                  :src="activeBook?.author_avatar || localImage('avator-icon.png')"
                />
                <span line-clamp-1>{{ activeBook?.author }}</span>
              </div>
              <span class="text-[#E5E5E5] mx-16px">|</span>
            </template>
            <div flex items-center>
              <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
              {{ activeBook?.category?.name }}
            </div>
            <span class="text-[#E5E5E5] mx-16px">|</span>
            <div flex items-center>
              <img w-16px h-16px rounded-full mr-8px :src="localImage('see-icon.png')" alt="" />
              <span>{{ activeBook?.display_num }}</span>
            </div>
          </div>
          <div line-clamp-3 min-h-60px text-16px color="#595959">
            {{ activeBook?.summary }}
          </div>
          <div my-16px text-18px color="#A6A6A6">
            {{ t('latestUpdate') }}:
            <span text-primary>{{ t('chapter_x', { x: activeBook.updated_chapters }) }}</span>
          </div>
          <NButton
            tag="a"
            :href="`/bookDetail?id=${activeBook.novel_id}`"
            textColor="#fff"
            class="btn font-semibold text-18px px-40px min-h-40px"
          >
            {{ t('read') }}
          </NButton>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="less" scoped>
:deep(.n-divider.n-divider--vertical) {
  --n-color: #d9d9d9 !important;
}

.mx-16px {
  margin-left: 16px;
  margin-right: 16px;
}
</style>
