<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { t } = useI18n()
const localLang = ref(process.env.LANG || 'en')

const { info } = props
const activeBook = ref<IBook>({
  id: 0,
})

const changeActiveBook = (currentIndex: number) => {
  activeBook.value = info.books?.[currentIndex] || { id: 0 }
}

onMounted(() => {
  const INFO = info.books as IBook[]
  if (INFO.length > 0) {
    activeBook.value = INFO[0]
  }
})
</script>

<template>
  <section flex gap-66px>
    <div w-570px rounded-xl flex-none overflow-hidden class="bg-[rgba(245,245,245,0.5)]">
      <div w-full h-247px relative top-0 left-0 overflow-hidden>
        <div
          w-full
          h-full
          absolute
          top-0
          left-0
          filter-blur-15px
          bg-no-repeat
          :style="{
            backgroundImage: `url(${info?.books?.[0].avatar})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
          }"
        />
        <div w-full h-full absolute top-0 left-0 class="bg-[rgba(0,0,0,0.5)]" />

        <div w-full h-full absolute top-0 left-0 flex items-center justify-center select-none>
          <NCarousel
            effect="card"
            prevSlideStyle="transform: translateX(-190%) translateZ(-228px);"
            nextSlideStyle="transform: translateX(90%) translateZ(-228px);"
            style="height: 200px"
            :showDots="false"
            :onUpdate:current-index="changeActiveBook"
          >
            <NCarouselItem
              v-for="(book, i) in info?.books?.slice(0, 3)"
              :key="book.novel_id"
              :style="{
                width: '150px',
                opacity: 1,
                overflow: 'hidden',
                borderRadius: '8px',
              }"
            >
              <div w-full h-full overflow-hidden rounded-lg relative top-0 left-0 cursor-pointer>
                <img object-cover img-hover :src="info?.books?.[i].avatar" alt="" />
                <img
                  object-cover
                  absolute
                  left-0
                  top-0
                  w-45px
                  h-42px
                  :src="localImage(`ranking/${i + 1}${localLang === 'ar' ? '-ar' : ''}.png`)"
                  alt=""
                />
              </div>
            </NCarouselItem>
          </NCarousel>
        </div>
      </div>

      <div flex flex-col items-center p-24px pt-16px>
        <h3 text-18px font-semibold>{{ activeBook.title }}</h3>
        <div flex items-center gap-24px pt-8px pb-16px color="#8C8C8C">
          <div v-if="activeBook?.author" flex items-center overflow-hidden>
            <NAvatar
              w-16px
              h-16px
              mr-8px
              round
              size="small"
              objectFit="cover"
              :src="activeBook?.author_avatar || localImage('avator-icon.png')"
            />
            <span line-clamp-1>{{ activeBook?.author }}</span>
          </div>
          <div flex items-center>
            <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
            {{ activeBook?.category?.name }}
          </div>
        </div>
        <div line-clamp-4 min-h-60px text-14px color="#595959">
          {{ activeBook?.summary }}
        </div>
        <NButton tag="a" :href="`/bookDetail?id=${activeBook.novel_id}`" mt-20px px-40px py-8px class="btn">
          {{ t('read') }}
        </NButton>
      </div>
    </div>
    <div flex gap-x-40px gap-y-25px flex-wrap>
      <a
        v-for="book in props?.info?.books?.slice(4, 12)"
        :key="book.novel_id"
        :href="`/bookDetail?id=${book.novel_id}`"
        w-111px
        flex-none
        cursor-pointer
        class="group"
      >
        <div w-full h-150px overflow-hidden rounded-lg>
          <img img-hover :src="book.avatar" />
        </div>
        <div mt-8px overflow-hidden>
          <h3 text-18px font-medium line-clamp-2 min-h-50px group-hover:text-primary>
            {{ book.title }}
          </h3>
          <div mt-2 color="#8C8C8C">{{ book?.category?.name }}</div>
        </div>
      </a>
    </div>
  </section>
</template>

<style lang="less" scoped></style>
