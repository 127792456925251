const __pages_import_0__ = () => import("/src/pages/[...all].vue");
import __pages_import_1__ from "/src/pages/index.vue";
const __pages_import_2__ = () => import("/src/pages/Login/index.vue");
const __pages_import_3__ = () => import("/src/pages/bookDetail/[novelId].vue");
const __pages_import_4__ = () => import("/src/pages/bookDetail/index.vue");
const __pages_import_5__ = () => import("/src/pages/bookshelf/index.vue");
const __pages_import_6__ = () => import("/src/pages/c/index.vue");
const __pages_import_7__ = () => import("/src/pages/classification/index.vue");
const __pages_import_8__ = () => import("/src/pages/dmca/index.vue");
const __pages_import_9__ = () => import("/src/pages/search/index.vue");
const __pages_import_10__ = () => import("/src/pages/sso/index.vue");
const __pages_import_11__ = () => import("/src/pages/typePages/index.vue");
const __pages_import_12__ = () => import("/src/pages/c/components/apple-pay.vue");
const __pages_import_13__ = () => import("/src/pages/c/components/card-pay.vue");
const __pages_import_14__ = () => import("/src/pages/c/components/cvc-pay.vue");
const __pages_import_15__ = () => import("/src/pages/c/components/google-pay.vue");
const __pages_import_16__ = () => import("/src/pages/c/components/pay-methods.vue");
const __pages_import_17__ = () => import("/src/pages/c/components/success-modal.vue");

const routes = [{"name":"all","path":"/:all(.*)*","component":__pages_import_0__,"props":true,"meta":{"layout":404}},{"name":"index","path":"/","component":__pages_import_1__,"props":true},{"name":"Login","path":"/login","component":__pages_import_2__,"props":true},{"name":"bookDetail-novelId","path":"/bookdetail/:novelId","component":__pages_import_3__,"props":true},{"name":"bookDetail","path":"/bookdetail","component":__pages_import_4__,"props":true},{"name":"bookshelf","path":"/bookshelf","component":__pages_import_5__,"props":true},{"name":"c","path":"/c","component":__pages_import_6__,"props":true},{"name":"classification","path":"/classification","component":__pages_import_7__,"props":true},{"name":"dmca","path":"/dmca","component":__pages_import_8__,"props":true},{"name":"search","path":"/search","component":__pages_import_9__,"props":true},{"name":"sso","path":"/sso","component":__pages_import_10__,"props":true},{"name":"typePages","path":"/typepages","component":__pages_import_11__,"props":true},{"name":"c-components-apple-pay","path":"/c/components/apple-pay","component":__pages_import_12__,"props":true},{"name":"c-components-card-pay","path":"/c/components/card-pay","component":__pages_import_13__,"props":true},{"name":"c-components-cvc-pay","path":"/c/components/cvc-pay","component":__pages_import_14__,"props":true},{"name":"c-components-google-pay","path":"/c/components/google-pay","component":__pages_import_15__,"props":true},{"name":"c-components-pay-methods","path":"/c/components/pay-methods","component":__pages_import_16__,"props":true},{"name":"c-components-success-modal","path":"/c/components/success-modal","component":__pages_import_17__,"props":true}];

export default routes;