import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import type { ILayoutCountdownItems } from '@/types/home'
import { asyncGetHomeTab, asyncGetHomeTabs } from '@/api/home'

export const useBookStore = defineStore('book', {
  state: () => ({
    // 是否自动解锁
    isAutoUnlock: Cookies.get('isAutoUnlock') ? JSON.parse(Cookies.get('isAutoUnlock') as string) : false,
    // 更多类型数据
    moreTypeData: JSON.parse(window.sessionStorage.getItem('typePagesInfo') || '{}'),
    layouts: [] as ILayoutCountdownItems[], // 获取首页TAB中的内容
  }),

  actions: {
    setIsAutoUnlock(flag: boolean) {
      this.isAutoUnlock = flag
      Cookies.set('isAutoUnlock', JSON.stringify(flag))
    },

    setMoreTypeData(data: ILayoutCountdownItems | {}) {
      this.moreTypeData = data
      window.sessionStorage.setItem('typePagesInfo', JSON.stringify(data))
    },

    async getLayout() {
      const res = await asyncGetHomeTabs()
      const tabs = res.data.tabs
      if (tabs && tabs.length > 0) {
        const tab = res.data.tabs[0].id
        const { data } = await asyncGetHomeTab(tab)
        // 过滤layout里面的banner
        this.layouts = data.layouts.filter((v: ILayoutCountdownItems) => v.type !== 4)
      }
    },
  },
})
