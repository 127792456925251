{
  "RemoveAllHistory": "ลบประวัติทั้งหมด",
  "SignInViewBooks": "ลงชื่อเช้าใช้ เพื่อดูนิยายเรื่องโปรดทุกเรื่องของคุณในชั้นวางหนังสือ",
  "about": "เกี่ยวกับ",
  "accountDontExist": "ไม่พบบัญชีนี้ ลองใช้ช่องทางอื่นดู คุณสามารถเข้าสู่ระบบด้วยอีเมล หรือ id ผู้ใช่",
  "active": "{x} OFF",
  "add": "เพิ่มเข้าชั้นวางหนังสือ",
  "addToLibrary": "เพิ่มเข้าชั้นวางหนังสือ",
  "addedToLibrary": "เพิ่มเข้าชั้นวางหนังสือ",
  "addedToLibraryBtn": "เพิ่มเข้าชั้นวางหนังสือ",
  "appliedOff": "เลือกแล้ว {x} OFF",
  "areYou": "แน่ใจหรือว่าจะลบนิยายทุกเรื่อง",
  "areYouRemoveRecords": "แน่ใจหรือว่าต้องการจะลบประวัติทั้งหมดของคุณ เพราะคุณจะไม่สามารถยกเลิกการกระทำนี้ได้",
  "areYouSure": "คุณแน่ใจหรือว่าจะออกจากระบบ",
  "autoUnlock": "ปลดล็อคอัตโนมัติ",
  "back": "กลับ",
  "background": "พื้นหลัง",
  "balance": "ยอดเงินคงเหลือในบัญชี",
  "beginReading": "เริ่มอ่าน",
  "betterPlans": "หลังจากเข้าสู่ระบบแล้ว การเติมเงินจะดีกว่าในแอพ",
  "bonus": "เหรียญฟรี",
  "booksInTotal": "ทั้งหมด{x}เล่ม",
  "cancel": "ยกเลิก",
  "cantactUs": "ติดต่อเรา",
  "card_holder_name": "ชื่อผู้ถือบัตร",
  "card_info": "ข้อมูลบัตร ATM",
  "card_number": "เลขบัตร",
  "cards": "เหรียญฟรีส่วนลด {x} OFF",
  "chapter_x": "ตอนที่ {x}",
  "cheapX": "มอบสูงถึง {x}%",
  "checkout": "ชำระเงิน",
  "chooseYourAccount": "เลือกบัญชีของคุณ",
  "chooseYourPlan": "เลือกแพ็คเกจของคุณ",
  "choose_a_coupon": "เลือกเหรียญฟรีส่วนลด",
  "choose_a_package": "เลือกผลิตภัณฑ์",
  "closeIn": "จะจบหลังจาก：",
  "coins": "เหรียญ",
  "coinsUnlockStories": "เหรียญ และเหรียญฟรีใช้ปลดล็อคตอนได้",
  "coinsUnlockThisChapter": "การซื้อตอนนี้จำเป็นต้องใช้เหรียญ/เหรียญฟรี {x}",
  "coins_added": "เหรียญเข้าบัญชีแล้ว",
  "coins_free": "{x} เหรียญ+เหรียญเหรียญฟรี{x} เหรียญ",
  "completed": "จบแล้ว",
  "confirm": "ยืนยัน",
  "confirm_your_account": "ยืนยันบัญชี",
  "continue": "อ่านต่อไป",
  "continueReadMore": "อ่านอย่างต่อเนื่องเพื่อค้นพบเรื่องที่สนุกสนานในแอป Tapon มากยิ่งขึ้น",
  "continue_topping_up": "เติมเงินต่อไป",
  "countBooks": "{x} เรื่อง",
  "countViews": "อ่านแล้ว {x} คน",
  "credit_or_debit_card": "บัตรเครดิตหรือบัตรเดบิต",
  "details": "รวมเหรียญ",
  "discountOffApplied": "ไม่ใช้เหรียญฟรีส่วนลด",
  "discountOffInactive": "ไม่มีเหรียญฟรีส่วนลดที่ใช้งานได้",
  "done": "ลบสำเร็จแล้ว",
  "edit": "แก่ไข",
  "enterBookName": "ใส่ชื่อนิยาย/ผู้เขียน",
  "enterYouIDorEmail": "ป้อน ID หรือ อีเมลที่พ่วงกับแอพพลิเคชั่น",
  "enter_card_holder_name": "โปรดกรอกชื่อผู้ถือบัตร",
  "enteryourId": "ใส่ ID ของคุณที่นี่",
  "expiration_date": "วันหมดอายุ",
  "expiredSeeTime": "หมดอายุแล้ว ครั้งหน้ารีบใช้หน่อยนะ",
  "expiresAtDate": "หมดอายุ {x}",
  "failed": "เข้าสู่ระบบล้มเหลว",
  "failedToLoad": "โหลดไม่สำเร็จ",
  "featuredStories": "รายการนิยายคุณภาพ",
  "followUs": "ติดตามเรา",
  "fontSize": "ขนาดตัวอักษร",
  "for_all_top_up_packages": "ใช้ได้กับการเติมเงินทั้งหมด",
  "for_x_only": "เพียง {x} ก็สามารถใช้ได้",
  "for_x_or_less": "ตั้งแต่ {x} ลงไปก็สามารถใช้ได้",
  "for_x_or_more": "ตั้งแต่ {x} ขึ้นไปก็สามารถใช้ได้",
  "free_coins": "เหรียญฟรี",
  "genres": "ประเภท",
  "genresTitle": "หมวดหมู่",
  "getBouns": "มอบ {x} เหรียญฟรี",
  "getMore": "ลดพิเศษจำกัดเวลา{x}",
  "getMoreCoins": "รับเหรียญมากยิ่งขึ้น",
  "getTheApp": "เพิ่มเข้าสู่ชั้นวางหนังสือ",
  "get_free_coins": "เหรียญฟรี",
  "goToAppTip": "ไปที่ App",
  "gotoTaponApp": "ไปยังแอป Tapon",
  "history": "ประวัติ",
  "home": "หน้าแรก",
  "hot": "ฮอตที่สุด",
  "inactiveOff": "{x} OFF ใช้งานไม่ได้",
  "including_free_coins": "+ {x} เหรียญโบนัส ",
  "iosCanAppleId": "ผู้ใช้งาน IOS สามารถล็อกอินด้วย Apple ID ของคุณ",
  "lastChapter": "ตอนก่อนหน้า",
  "latestUpdate": "ตอนล่าสุด",
  "legal": "ประกาศถึงผู้ใช้งาน",
  "library": "ชั้นวางหนังสือ",
  "limitedTimeOffer": "แฟลชเซลล์ลด{x}%",
  "limited_time_offer": "เหรียญเหรียญฟรีมีเวลาที่จำกัด",
  "limited_time_offers": "ข้อเสนอจำกัดเวลา",
  "list": "สารบัญ",
  "loggedIn": "เข้าสู่ระบบสำเร็จ",
  "loginMoreStory": "เข้าสู่ระบบเพื่ออ่านเรื่องราวที่สนุกมากมาย",
  "loginTip": "คลิกเพื่อเข้าสู่ระบบเพื่อยอมรับ\n{a}และ{b}",
  "more": "มากยิ่งขึ้น",
  "moreBonusTip": "รับเหรียญฟรีที่นี่ได้มากกว่าใน APP",
  "moreOptions": "ตัวเลือกเพิ่มเติม",
  "moreThanApp": "มากกว่า App {x}%",
  "need_pay": "จำเป็นต้องชำระเงิน",
  "netWorkError": "เกิดข้อผิดพลาดในการเชื่อมต่อเครือข่าย",
  "newRelease": "ออกใหม่",
  "next": "หน้าถัดไป",
  "nextChapter": "ตอนถัดไป",
  "noActiveCoupons": "เหรียญฟรีส่วนลด",
  "noContent": "ไม่มีเนื้อหา",
  "noFindServe": "ขอโทษด้วย บริการของเรายังไม่เปิดให้บริการในประเทศของคุณ",
  "noResultsFound": "ไม่พบเรื่องที่คุณต้องการจะอ่าน",
  "notFoundUser": "ไม่พบผู้ใช้นี้ กรุณากรอกใหม่อีกครั้ง",
  "notNow": "ไม่ใช่ตอนนี้",
  "notUseAnyCoupons": "ส่วนลด",
  "okay": "โอเค",
  "ongoing": "กำลังอัฟเดท",
  "or": "หรือ",
  "order": "จัดลำดับ",
  "originalPrice": "ชำระเงิน",
  "otherOptions": "เข้าสู่ระบบด้วยวิธีอื่นๆ",
  "other_plans": "ข้อเสนออื่นๆ",
  "pay": "TOP UP",
  "pay_now": "ชำระเงินเลย",
  "pay_with_apple_pay": "คลิกใช้ Apple Pay",
  "pay_with_credit": "ชำระเงินด้วยบัตรเครดิตหรือบัตรเดบิต",
  "pay_with_google_pay": "คลิกใช้ Google Pay",
  "paymentMethod": "ช่องทางการจ่ายเงิน",
  "payment_successful": "ชำระเงินสำเร็จ",
  "platformTip": "เข้าสู่ระบบด้วย {x}",
  "previous": "หน้าก่อนหน้า",
  "price": "ราคา",
  "privacyPolicy": "นโยบายความเป็นส่วนตัว",
  "privacy_policy_content": "",
  "privacy_policy_title": "",
  "purchaseFailed": "การสั่งซื้อล้มเหลว",
  "purchaseSucceeded": "การจ่ายสำเร็จ",
  "read": "อ่าน",
  "read2": "อ่าน",
  "readItInTheApp": "อ่านใน APP",
  "readItNow": "อ่านเลย",
  "readTheBookInLibrary": "อ่านนิยายในชั้นวางหนังสือของคุณ",
  "rec": "แนะนำ",
  "rechargeCoupon": "ราคาเดิม",
  "rechargeSave": "รายละเอียด",
  "refresh": "โหลดใหม่อีกครั้ง",
  "remove": "ลบ",
  "resources": "ทรัพยากร",
  "saveCoupon": "ส่วนลด{x}",
  "save_my_card_for_future": "บันทึกบัตรของฉันไว้เพื่อใช้ชำระเงินในอนาคต",
  "save_x": "ลดทันที{x}",
  "security_code": "รหัสความปลอดภัย",
  "seeMore": "มากยิ่งขึ้น",
  "select_payment_method": "เลือกช่องทางการชำระเงิน",
  "signID": "เข้าสู่ระบบด้วย ID ผู้ใช้งาน",
  "signIn": "เข้าสู่ระบบ",
  "signIntoTapon": "เข้าสู่ระบบใน Tapon",
  "signOut": "ออกจากระบบ",
  "signOutCancelledShep": "การเติมเงินของคุณจะถูกยกเลิก หากคุณออกจากระบบ",
  "signUpAppleID": "ผู้ใช้ใดที่ลงชื่อด้วย Apple ID  อาจจะลงชื่อเข้าใช้ด้วย ID ผู้ใช้งาน {value} และเช็ค ID ผู้ใช้ ที่อยู่ข้างๆ รูปโปรไฟล์ของคุณตรง \"ฉัน\"",
  "startIn": "จะเริ่มหลังจาก：",
  "start_reading_on_tapon": "ไปอ่านที่แอป Tapon",
  "switch_account": "สลับบัญชี",
  "synopsis": "เรื่องย่อ",
  "tags": "แท็ก",
  "termsOfUse": "ข้อตกลงการใช้งาน",
  "title": "Tapon: ห้องสมุดนิยายออนไลน์ที่อยู่ใกล้เพียงปลายนิ้วคุณ",
  "toCreate": "สร้าง",
  "topCount": "count",
  "topUp": "ศูนย์เติมเงิน",
  "topUpBtn": "เติมเงิน",
  "tryAnother": "ลองอ่านเรื่องอื่นเถอะ",
  "unknownError": "ข้อผิดพลาดที่ไม่รู้จัก",
  "unlockNow": "ปลดล็อกตอนนี้เลย",
  "use_new_card": "ใช้บัตรอื่นๆ",
  "user_agreement_content": "",
  "user_agreement_title": "",
  "user_id": "ID ผู้ใช้งาน",
  "username": "ชื่อผู้ใช้งาน",
  "vip_content": "",
  "vip_title": "",
  "welcomeAppnameTip": "ยินดีต้อนรับเข้าสู่ Tapon",
  "x_coins": "{x} เหรียญ",
  "x_inactive_coupons": "เหรียญฟรีส่วนลด {x} ใบใช้งานไม่ได้",
  "youIdIncorrect": "ไม่พบผู้ใช้งานนี้",
  "youMayAlsoLike": "คุณอาจจะชอบด้วย",
  "you_will_get": "รับ"
}