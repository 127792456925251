import type { App } from '@vue/runtime-core'
import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from '~pages'

const routes = setupLayouts(generatedRoutes)
export const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: 'smooth' }
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})

export const install = (app: App<Element>) => {
  // router.isReady().then(async () => {
  //   const { registerSW } = await import('virtual:pwa-register')
  //   registerSW({ immediate: true })
  // })
  app.use(router)
}
