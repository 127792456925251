<!--  -->
<script lang="ts" setup>
import type { IBook, ILayoutItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutItems
}>()

const { t } = useI18n()
const router = useRouter()

const { info } = props
const infoOne = info?.books?.[0] || {
  novel_id: '',
  avatar: '',
  title: '',
  category: {
    name: '',
  },
  author_avatar: '',
  author: '',
  summary: '',
}

const otherList: IBook[] = info?.books?.slice(1) || []
</script>

<template>
  <section flex gap-88px items-center>
    <div w-384px h-497px flex-none rounded-3xl relative top-0 left-0 class="bg-[rgba(245,245,245,0.5)] group">
      <img absolute top-0 left-0 w-full :src="localImage('book-bg.png')" alt="" />
      <div absolute top-0 left-0 w-full h-full flex flex-col items-center p-24px>
        <div w-150px h-200px overflow-hidden rounded-lg flex-none>
          <img img-hover :src="infoOne.avatar" alt="" />
        </div>
        <h3 text-18px font-semibold line-clamp-1 mt-16px group-hover:text-primary>
          {{ infoOne.title }}
        </h3>
        <div flex items-center gap-24px mt-8px color="#8C8C8C">
          <template v-if="infoOne?.author">
            <div flex items-center>
              <NAvatar
                w-16px
                h-16px
                rounded-full
                mr-8px
                size="small"
                objectFit="cover"
                :src="infoOne?.author_avatar || localImage('avator-icon.png')"
              />
              <span>{{ infoOne?.author }}</span>
            </div>
          </template>
          <div flex items-center>
            <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
            <span>{{ infoOne?.category?.name }}</span>
          </div>
        </div>
        <div text-center mt-16px mb-24px line-clamp-5 text="#595959">
          {{ infoOne.summary }}
        </div>
        <NButton
          type="primary"
          class="btn"
          px-40px
          h-32px
          text-16px
          min-h-40px
          @click="router.push(`/bookDetail?id=${infoOne.novel_id}`)"
        >
          {{ t('read') }}
        </NButton>
      </div>
    </div>

    <div w-full flex flex-wrap gap-24px>
      <a
        v-for="book in otherList.slice(0, 6)"
        :key="book.novel_id"
        :href="`/bookDetail?id=${book.novel_id}`"
        w-352px
        h-128px
        cursor-pointer
        flex
        gap-16px
        rounded-lg
        class="group"
      >
        <div w-92px h-128px overflow-hidden rounded-lg flex-none>
          <img img-hover :src="book.avatar" />
        </div>
        <div overflow-hidden color="#BFBFBF">
          <h3 text-16px font-medium line-clamp-2 h-44px color="#262626" group-hover:text-primary>
            {{ book.title }}
          </h3>
          <div flex items-center mt-2>
            <img w-16px h-16px rounded-full mr-2 :src="localImage('tag-icon.png')" alt="" />
            {{ book?.category?.name }}
          </div>
          <div v-if="book?.author" flex items-center mt-2 overflow-hidden>
            <NAvatar
              flex-none
              w-16px
              h-16px
              mr-8px
              round
              size="small"
              objectFit="cover"
              :src="book?.author_avatar || localImage('avator-icon.png')"
              :fallback-src="localImage('avator-icon.png')"
            />
            <span line-clamp-1>{{ book?.author }}</span>
          </div>
          <div flex items-center mt-2>
            <img w-16px h-16px rounded-full mr-2 :src="localImage('see-icon.png')" alt="" />
            <span>{{ book?.display_num }}</span>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<style lang="less" scoped></style>
