<script setup lang="ts">
const props = defineProps<{
  bulbImg?: string
}>()
const router = useRouter()
const route = useRoute()

const targetPath = ref('/c')

const jumpFun = () => {
  router.push(targetPath.value)
  zaq('taponweb-homesmallbulb-click')
}
onMounted(async () => {
  zaq('taponweb-homesmallbulb-view')
})
</script>

<template>
  <div>
    <div
      v-if="targetPath !== route.path && !route.path.includes('/bookDetail/') && props.bulbImg"
      class="flex-box"
      @click="jumpFun()"
    >
      <img :src="props.bulbImg" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.flex-box {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  position: fixed;
  bottom: 100px;
  right: 100px;
  cursor: pointer;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
