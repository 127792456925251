<script setup lang="ts">
import type { NDateLocale } from 'naive-ui'
import {
  arDZ,
  dateArDZ,
  dateEnUS,
  dateEsAR,
  dateFrFR,
  dateIdID,
  dateKoKR,
  datePtBR,
  dateRuRU,
  dateThTH,
  dateZhCN,
  dateZhTW,
  enUS,
  esAR,
  frFR,
  idID,
  koKR,
  ptBR,
  ruRU,
  thTH,
  useDialog,
  useMessage,
  useNotification,
  zhCN,
  zhTW,
  deDE,
  dateDeDE,
  jaJP,
  dateJaJP,
} from 'naive-ui'
import { COLORS } from '@/utils/const'

const { locale } = useI18n()
const appStore = useAppStore()

const langMap: Record<string, [any, NDateLocale]> = {
  en: [enUS, dateEnUS],
  es: [esAR, dateEsAR],
  id: [idID, dateIdID],
  ko: [koKR, dateKoKR],
  ru: [ruRU, dateRuRU],
  pt: [ptBR, datePtBR],
  th: [thTH, dateThTH],
  hans: [zhCN, dateZhCN],
  hant: [zhTW, dateZhTW],
  ar: [arDZ, dateArDZ],
  fr: [frFR, dateFrFR],
  de: [deDE, dateDeDE],
  ja: [jaJP, dateJaJP],
}

let langRef = ref(langMap[locale.value])

watch(locale, value => {
  langRef.value = langMap[value]
})
const themeOverrides = {
  common: {
    primaryColor: COLORS.primary,
    primaryColorHover: COLORS.hover,
    primaryColorActive: COLORS.primary,
    primaryColorPressed: COLORS.primary,
  },
  Button: {
    backgroundColor: COLORS.primary,
    primaryColor: COLORS.primary,
    border: 'none',
  },
}

// 挂载naive组件的方法至window, 以便在全局使用
function setupNaiveTools() {
  // window.$loadingBar = useLoadingBar()
  window.$notification = useNotification()

  window.$message = useMessage()
  window.$dialog = useDialog()
}

const NaiveProviderContent = defineComponent({
  setup() {
    setupNaiveTools()
  },
  render() {
    return h('div')
  },
})
</script>

<template>
  <NConfigProvider :themeOverrides="themeOverrides" :locale="langRef[0]" :dateLocale="langRef[1]">
    <NDialogProvider>
      <NNotificationProvider>
        <NMessageProvider>
          <Loading2 v-show="appStore.isLoading" />
          <!-- <Loading v-show="appStore.isLoading" /> -->
          <slot />
          <NaiveProviderContent />
        </NMessageProvider>
      </NNotificationProvider>
    </NDialogProvider>
  </NConfigProvider>
</template>
