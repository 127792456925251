import * as OpenCC from 'opencc-js/core'
import * as Locale from 'opencc-js/preset'

export const lang = process.env.LANG || 'en'

export const languageTransform = (key?: string) => {
  if (!key) return ''
  if (lang === 'hant') {
    const converter = OpenCC.ConverterFactory(Locale.from.cn, Locale.to.hk)
    return converter(key)
  } else if (lang === 'hans') {
    const converter = OpenCC.ConverterFactory(Locale.from.hk, Locale.to.cn)
    return converter(key)
  }
  return key
}
