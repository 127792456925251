// 获取首页tab
export function asyncGetHomeTabs() {
  return request({
    url: '/tapon/home_tabs',
    method: 'get',
  })
}

// layout
export function asyncGetHomeTab(tab_id: number) {
  return request({
    url: '/tapon/home',
    method: 'get',
    params: {
      tab_id,
    },
  })
}

// 获取banner
export function asyncGetBanners(params: any) {
  return request({
    url: '/tapon/banners',
    method: 'get',
    params,
  })
}

// 获取无限刷小说
export function asyncGetNovels({ page, per_page }: { page?: number; per_page?: number }) {
  return request({
    url: '/tapon/book/unlimited',
    method: 'get',
    params: {
      page,
      per_page,
    },
  })
}
