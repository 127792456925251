<script setup lang="ts">
const appStore = useAppStore()
const route = useRoute()
const { t, locale } = useI18n()

const isRead = computed(() => route.path.includes('/bookDetail/'))

onMounted(() => {
  useTitle(t('title'))
  const $html = document?.getElementsByTagName('html') as HTMLCollection
  if ($html) $html[0].setAttribute('lang', locale.value)
})
</script>

<template>
  <main :style="{ background: isRead ? appStore.readTheme.primary : '#fff' }">
    <Top v-if="appStore.hasHeadAndFoot" />
    <main :class="{ 'pt-80px': appStore.hasHeadAndFoot }">
      <RouterView />
      <NBackTop rounded-lg :right="100">
        <div w-44px h-44px rounded-lg flex justify-center items-center bg="#F5F5F5">
          <img w-30px h-28px :src="localImage('to-top-icon.png')" alt="" />
        </div>
      </NBackTop>
    </main>
    <Footer v-if="appStore.hasHeadAndFoot" :bgColor="isRead ? appStore.readTheme.secondary : '#F5F5F5'" />
  </main>
</template>

<style lang="less" scoped>
main {
  min-height: 57vh;
}
</style>
