<!--  -->
<script lang="ts" setup>
import type { ILayoutItems } from '@/types/home'
const props = defineProps<{
  info: ILayoutItems
}>()

const { t } = useI18n()
const { info } = props
const localLang = ref(process.env.LANG || 'en')
const hoverFun = (i: number) => {
  // const num = i * (160 + 24) + 560 - 1200
  // const slide = document.querySelector('#carousel-style-3 .n-carousel__slides') as HTMLElement
  // const $document = document.defaultView as any
  // const translateX = $document.getComputedStyle(slide).transform.split(',')[4]
  // console.log(num, translateX)
  // slide.style.transform = `translateX(${-num <= +translateX ? -num : translateX}px)`
}
</script>

<template>
  <section overflow-hidden h-432px relative mt-56px select-none>
    <img absolute left-0 top-0 w-full h-full :src="info?.books?.[0]?.avatar" alt="avatar" />
    <div absolute left-0 top-0 w-full h-full flex justify-center pt-32px class="bg">
      <div web-container>
        <Title :name="info.name" :info="info" titleColor="#D9D9D9" />
        <section flex gap-24px>
          <NCarousel
            id="carousel-style-3"
            w-full
            relative
            slidesPerView="auto"
            :spaceBetween="24"
            :loop="false"
            showArrow
            draggable
          >
            <NCarouselItem
              v-for="(book, i) in info?.books?.slice(0, 12)"
              :key="book.novel_id"
              flex-none
              cursor-pointer
              bg-white
              rounded-lg
              text-16px
              class="carousel"
              @mouseover="hoverFun(i)"
            >
              <a :href="`/bookDetail?id=${book.novel_id}`" flex text-14px relative>
                <img
                  :src="localImage(`ranking/${i + 1}${localLang === 'ar' ? '-ar' : ''}.png`)"
                  alt=""
                  absolute
                  top-0
                  left-0
                  w-45px
                  h-42px
                  z-10
                />
                <div w-160px h-213px overflow-hidden rounded-lg flex-none>
                  <img img-hover :src="book.avatar" />
                </div>
                <div p-24px w-full h-213px overflow-hidden class="carousel-info">
                  <h3 text-18px font-medium line-clamp-1>{{ book.title }}</h3>
                  <div mt-2 line-clamp-3 min-h-60px color="#8C8C8C">
                    {{ book?.summary }}
                  </div>
                  <div my-16px>
                    <span :class="book?.serial_status === 1 ? 'text-[#FFB133]' : 'color-primary'">
                      {{ book?.serial_status_text }}
                    </span>
                    <span class="text-[#ccc] mx-10px">|</span>
                    <span color="#737373">{{ t('chapter_x', { x: book?.updated_chapters }) }}</span>
                  </div>
                  <div w-full flex items-center justify-between color="#BFBFBF">
                    <div flex items-center>
                      <template v-if="book?.author">
                        <div flex items-center overflow-hidden>
                          <NAvatar
                            w-16px
                            h-16px
                            mr-8px
                            round
                            size="small"
                            objectFit="cover"
                            :src="book?.author_avatar || localImage('avator-icon.png')"
                          />
                          <span line-clamp-1>{{ book?.author }}</span>
                        </div>
                        <span class="text-[#E5E5E5] mx-16px">|</span>
                      </template>
                      <div flex items-center>
                        <img w-16px h-16px rounded-full mr-8px :src="localImage('tag-icon.png')" alt="" />
                        {{ book?.category?.name }}
                      </div>
                    </div>
                    <div flex items-center>
                      <img w-16px h-16px rounded-full mr-8px :src="localImage('see-icon.png')" alt="" />
                      <span>{{ book?.display_num }}</span>
                    </div>
                  </div>
                </div>
              </a>
            </NCarouselItem>

            <!-- 左右切换按钮 -->
            <template #arrow="{ to, total }">
              <button type="button" class="curtom-arrow-btn left" absolute top-74px @click="to(0)">
                <div :class="localLang === 'ar' ? 'i-carbon-chevron-right' : 'i-carbon-chevron-left'" />
              </button>
              <button type="button" class="curtom-arrow-btn right" absolute top-74px @click="to(total)">
                <div :class="localLang === 'ar' ? 'i-carbon-chevron-left' : 'i-carbon-chevron-right'" />
              </button>
            </template>

            <!-- 底部切换 -->
            <template #dots="{ total, currentIndex, to }">
              <div flex justify-end text-16px mt-4 gap-8px class="text-[rgba(255,255,255,0.3)]">
                <div
                  cursor-pointer
                  flex
                  items-end
                  :class="currentIndex === 0 ? 'text-[#A6A6A6] text-20px' : ''"
                  @click="to(0)"
                >
                  <div
                    mr-3
                    mb-2
                    h-0
                    scale-y-50
                    transition-all
                    duration-300
                    border="~ solid #A6A6A6"
                    :class="currentIndex === 0 ? 'w-40px opacity-100' : 'w-0 opacity-0'"
                  />
                  <span>01</span>
                </div>
                <div
                  cursor-pointer
                  flex
                  items-end
                  :class="currentIndex === total - 1 ? 'text-[#A6A6A6] text-20px' : ''"
                  @click="to(total)"
                >
                  <div
                    mr-3
                    mb-2
                    h-0
                    scale-y-50
                    transition-all
                    duration-300
                    border="~ solid #A6A6A6"
                    :class="currentIndex === total - 1 ? 'w-40px opacity-100' : 'w-0 opacity-0'"
                  />
                  <span>02</span>
                </div>
              </div>
            </template>
          </NCarousel>
        </section>
      </div>
    </div>
  </section>
</template>

<style lang="less" scoped>
.bg {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(100px);
}

.carousel {
  width: 160px !important;
  transition: width 0.15s cubic-bezier(0, 0, 0.58, 1);

  .carousel-info {
    display: none;
    opacity: 0;
  }

  &:hover {
    width: 560px !important;

    .carousel-info {
      display: block;
      opacity: 1;
    }
  }
}
</style>
