<script lang="ts" setup>
import type { ILayoutCountdownItems } from '@/types/home'

const props = defineProps<{
  info: ILayoutCountdownItems
}>()

const { info } = props
</script>

<template>
  <Title :name="info.name" :info="info">
    <template #left>
      <Countdown :start-time="info?.countdown.start_time_ts || 0" :end-time="info?.countdown.end_time_ts || 0" />
    </template>
  </Title>
  <section flex flex-wrap gap-24px>
    <a
      v-for="book in info?.books?.slice(0, 6)"
      :key="book.novel_id"
      :href="`bookDetail?id=${book.novel_id}`"
      w-384px
      p-8px
      flex-none
      cursor-pointer
      flex
      gap-8px
      rounded-lg
      class="bg-[rgba(245,245,245,0.5)] group"
    >
      <div w-92px h-122px overflow-hidden rounded-lg flex-none relative>
        <img img-hover :src="book.avatar" />
        <CountDownTip :discount="info.countdown.discount" />
      </div>
      <div overflow-hidden>
        <h3 text-18px font-medium line-clamp-2 h-50px mt-2 group-hover:text-primary>
          {{ book.title }}
        </h3>
        <div my-8px>
          <span :class="book?.serial_status === 1 ? 'text-[#FFB133]' : 'color-primary'">
            {{ book?.serial_status_text }}
          </span>
          <span class="text-[#E5E5E5] mx-8px">|</span>
          <span color="#8C8C8C">{{ book?.category?.name }}</span>
        </div>
        <div mt-2 flex items-center color="#BFBFBF">
          <img w-16px h-16px rounded-full mr-8px :src="localImage('see-icon.png')" alt="" />
          <span>{{ book?.display_num }}</span>
        </div>
      </div>
    </a>
  </section>
</template>

<style lang="less" scoped></style>
