import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

export const useAppStore = defineStore('app', {
  state: () =>
    ({
      language: Cookies.get('language') || 'en', // 语言
      hasHeadAndFoot: true, // 是否显示头部和底部组件
      isLoading: false, // 是否显示加载中组件
      // 阅读主题
      readTheme: Cookies.get('readTheme')
        ? JSON.parse(Cookies.get('readTheme') as string)
        : {
            id: '1',
            primary: '#F5F5F5',
            secondary: '#f0f0f0',
          },
      // 阅读字体
      readFont: Cookies.get('readFont') || 16,
    }) as { [v: string]: any },

  actions: {
    setIsLoading(flag: boolean) {
      this.isLoading = flag
    },

    setAppCookieData(name: string, data: any) {
      this[name] = data
      Cookies.set(name, isString(data) ? data : JSON.stringify(data))
    },
  },
})
